import lj from '../lumberjack';
import { getUrlVars, debounce, isInViewport, qs, setCookie } from './util';

const currentPageID = $(document.body).attr('id');
const containerPageID = $('.main-container').attr('id');
const urlParams = getUrlVars();
const { utm_adgroup } = urlParams;

/*
  Selecting banner elements
*/
const banners = $(
  '#topbar-container.banner-lake, #thirdwatch-top-banner.banner-lake'
);
const bannerLink = banners.children('a');
const bannerISLink = banners.find('button a');
const bannerTimer = banners.find('.container .nomob .timer');
const bannerButtons = banners.find('.container button');
const bannerTextNoMob = banners.find('.container .nomob .banner-text');
const bannerTextMob = banners.find('.container .mob .banner-text');

const bannerContent = banners.find('.banner-content');
const bannerPlaceholder = banners.find('.placeholder-banner-container');

const nplSection = $(
  'section.product-suite .container .wrapper-product > div:not(:first)'
);

export const updateBanner = () => {
  /*
    Removing banner placeholder after all changes are done
  */
  if (bannerContent.length && bannerPlaceholder.length) {
    bannerContent.css('display', 'block');
    bannerPlaceholder.css('display', 'none');
  }
};

const bannerTrackEvents = () => {
  let bannerHoverEventFired = false;

  /*
    Banner track events
  */
  if (banners.length) {
    // Track on banner load
    const bannerText = bannerTextNoMob.text();
    const bannerId = bannerLink.attr('id') || bannerISLink.attr('id');
    const eventProperties = {
      bannerText,
      bannerId
    };

    lj.pushEvents({
      event_name: 'website.display_banner',
      event_type: 'initiated',
      properties: eventProperties
    });

    // Track on banner click
    let currentBannerLink = $();
    if (bannerLink.length) currentBannerLink = bannerLink;
    else if (bannerISLink.length) currentBannerLink = bannerISLink;

    currentBannerLink.on('click', function() {
      lj.pushEvents({
        event_name: 'website.click_banner_cta1',
        event_type: 'initiated',
        properties: eventProperties
      });
    });

    banners.on('mouseenter', function() {
      if (!bannerHoverEventFired) {
        lj.pushEvents({
          event_name: 'website.hover_banner',
          event_type: 'success',
          properties: eventProperties
        });
        bannerHoverEventFired = true;
      }
    });

    setTimeout(() => {
      lj.pushEvents({
        event_name: 'website.impression_banner',
        event_type: 'success',
        properties: eventProperties
      });
    }, 5000);
  }
};

const whatsNewTrackEvents = () => {
  const hasEventFired = {};
  const hasHoverEventFired = {};
  function handleDataLakeEvents(element, ID) {
    if (element && ID && isInViewport(element) && !hasEventFired[ID]) {
      lj.pushEvents({
        event_name: 'website.display_newproducts',
        event_type: 'success',
        properties: {
          ID
        }
      });
      hasEventFired[ID] = true;
    }
  }

  nplSection.each(function(index) {
    const nplLink = $(this).find('a');
    const nplTitle = $(this).find('a h3.npl-title');
    const idContent = nplLink.attr('id') || nplTitle.text();
    const page_fold = $(this)[0]
      .closest('[data-fold-order]')
      .getAttribute('data-fold-order');
    if (idContent) {
      const element = $(this)[0];
      const ID = `card${index + 1}_${idContent.replace(' ', '_')}`;
      window.addEventListener(
        'scroll',
        debounce(() => handleDataLakeEvents(element, ID), 100)
      );
      $(this).on('click', () => {
        lj.pushEvents({
          event_name: 'website.click_newproduct_card',
          event_type: 'initiated',
          properties: {
            ID,
            title: nplTitle.text(),
            redirectUrl: nplLink.attr('href'),
            redirect_url: `${location.hostname}${nplLink.attr('href')}`,
            page_fold
          }
        });
      });
      $(this).on('mouseenter', () => {
        if (!hasHoverEventFired[ID]) {
          lj.pushEvents({
            event_name: 'website.hover_newproducts',
            event_type: 'success',
            properties: {
              ID,
              title: nplTitle.text(),
              redirectUrl: nplLink.attr('href'),
              redirect_url: `${location.hostname}${nplLink.attr('href')}`,
              page_fold
            }
          });
          hasHoverEventFired[ID] = true;
        }
      });
    }
  });
};

const trackGrowthAssets = () => {
  bannerTrackEvents();
  whatsNewTrackEvents();
};

export default trackGrowthAssets;

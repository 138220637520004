/* This scroll keeps the item always in center of visible list whenever possible */

import { scrollTo } from './util';

const OVERFLOW_THRESHOLD = 96;
var currentTestimonial = 0;

var getAnalyticsObj = function(el) {
  var category = $(el).attr('data-lytics-category');
  if (!category) {
    return;
  }

  return {
    eventCategory: category
  };
};

var nextTestimonial = function(e) {
  var $this = $(this),
    $scroller = $this.siblings('.scroller'),
    itemWidth = $scroller.find('.item')[0].offsetWidth,
    currentPos = $scroller.scrollLeft(),
    nextItem = (2 + (currentPos - itemWidth * 0.75) / itemWidth) >> 0,
    nextPos = itemWidth * nextItem;

  currentTestimonial = nextItem;

  var evtObj = getAnalyticsObj(this.parentElement);

  if (evtObj) {
    evtObj.eventAction = 'Click - Testimonial Card Right';

    if (e.originalEvent) {
      // Actually clicked the button.
      evtObj.eventAction = 'Click - Testimonial Right Arrow';
    }

    evtObj.eventLabel = nextItem - 1;

    window.rzpAnalytics(evtObj, function() {
      scrollTo.call($scroller[0], nextPos);
    });
  } else {
    scrollTo.call($scroller[0], nextPos);
  }
};

var prevTestimonial = function(e) {
  var $this = $(this),
    $scroller = $this.siblings('.scroller'),
    itemWidth = $scroller.find('.item')[0].offsetWidth,
    currentPos = $scroller.scrollLeft(),
    prevItem = ((currentPos - itemWidth * 0.75) / itemWidth) >> 0,
    prevPos = itemWidth * prevItem;

  currentTestimonial = prevItem;

  var evtObj = getAnalyticsObj(this.parentElement);

  if (evtObj) {
    evtObj.eventAction = 'Click - Testimonial Card Left';

    if (e.originalEvent) {
      // Actually clicked the button.
      evtObj.eventAction = 'Click - Testimonial Left Arrow';
    }

    evtObj.eventLabel = prevItem + 1;

    window.rzpAnalytics(evtObj, function() {
      scrollTo.call($scroller[0], prevPos);
    });
  } else {
    scrollTo.call($scroller[0], prevPos);
  }
};

$('.js-carousel .next-arrow').click(nextTestimonial);
$('.js-carousel .prev-arrow').click(prevTestimonial);

$('.js-carousel .scroller').each(function() {
  this.addEventListener(
    'scroll',
    function() {
      var $this = $(this),
        currentPos = $this.scrollLeft(),
        scrollWidth = this.scrollWidth,
        itemWidth = $this.find('.item').outerWidth();

      if (currentPos < OVERFLOW_THRESHOLD) {
        $this.siblings('.prev-arrow').addClass('hide');
      } else if (
        scrollWidth - currentPos - $this.find('.wrapper').innerWidth() <
        OVERFLOW_THRESHOLD
      ) {
        $this.siblings('.next-arrow').addClass('hide');
      } else {
        $this.siblings('.prev-arrow, .next-arrow').removeClass('hide');
      }
    },
    { passive: true }
  );
});

$('.testimonials .item').on('click', function(event) {
  var testimonial = $(this).index();
  if (currentTestimonial === testimonial || testimonial < 0) return;
  if (testimonial > currentTestimonial) {
    $('.js-carousel')
      .find('.next-arrow')
      .click();
  } else {
    $('.js-carousel')
      .find('.prev-arrow')
      .click();
  }
});

var COVER_HOVER_CLASS = 'over';

$('.testimonials .item').hover(
  function(event) {
    var testimonial = $(this).index();
    if (currentTestimonial === testimonial || testimonial < 0) return;
    if (testimonial > currentTestimonial) {
      $('.cover.right').addClass(COVER_HOVER_CLASS);
    } else {
      $('.cover.left').addClass(COVER_HOVER_CLASS);
    }
  },
  function() {
    $('.cover.right').removeClass(COVER_HOVER_CLASS);
    $('.cover.left').removeClass(COVER_HOVER_CLASS);
  }
);

import {
  transformLJEventsForSegment,
  emitSegment
} from './segment/emitSegment';
import { getLjEventProps, getSessionInfo } from './getLjEventProps';
import { getCookie, toTitleCase } from './modules/util';
import { isMobile } from './segment/segment-utils';

class LJEvents {
  constructor() {
    this.commonProperties = {};
    this.eventQueue = [];
    this.trackQueue = [];
  }

  setupLJ(variant = 'original') {
    let utm = null,
      gclid = null,
      midExists = false,
      x_mid_exist = false,
      browser_details = {},
      /* The attributed utm property as per GA's logic.
         This UTM property will tell to which campaign
         the user is attributed to.
         The logic is written in static repo.
         https://github.com/razorpay/static/blob/master/src/analytics/js/getAttributedUtms.js
      */
      attrib_utm = null;
    try {
      attrib_utm = JSON.parse(getCookie('lastAttribUtm'));
    } catch (e) {
      console.error('Error parsing lastAttribUtm cookie');
    }
    if (typeof window.razorpayAnalytics !== 'undefined') {
      utm = razorpayAnalytics.utils.getLandingParams();
      gclid = razorpayAnalytics.utils.getCookie('gclid');
      browser_details = razorpayAnalytics.utils.getBrowserDetails();
      midExists = razorpayAnalytics.utils.getCookie('midExists') || false;
      x_mid_exist = razorpayAnalytics.utils.getCookie('x_mid_exist') || false;

      window.rzpQ = razorpayAnalytics.createQ({ pollFreq: 500 });
      window.rzpQ.defineEventModifiers({
        onbr: [
          { propertyName: 'event_type', value: 'onboarding-events' },
          {
            propertyName: 'event_group',
            value: 'onboarding'
          }
        ]
      });
    }
    this.commonProperties = {
      utm_params: utm,
      gclid: gclid,
      mode: 'live',
      referring_url: document.referrer,
      url: document.location.href,
      variant,
      attrib_utm,
      experiment_ID: sessionStorage.getItem('experimentId') || '',
      clientId: getCookie('clientId'),
      midExists,
      x_mid_exist
    };

    Object.assign(this.commonProperties, browser_details);

    let properties = getLjEventProps();
    let sessionInfo = getSessionInfo();
    this.pushEvents({
      event_name: 'website.page',
      event_type: 'viewed',
      properties: {
        title: document.getElementsByTagName('title')[0].innerText,
        midExists,
        x_mid_exist,
        first_page_session: properties.isFirstPageSession,
        first_page_user: properties.isFirstPageUser,
        version: 1,
        new_user: properties.isNewUser,
        first_page: properties.firstPage,
        // true if the current page matches the first page user visited on razorpay.com.
        // First page is stored in cookie
        // This will be try only once in user journey -> just for the first page visited
        // if a user comes back to the same page again, it will be false
        is_landing_page_user: properties.isLandingPageUser,
        is_landing_page_session: sessionInfo.isLandingPageSession,
        common_session_id: sessionInfo.commonSessionId,
        first_utm: properties.firstUtm,
        last_utm: properties.lastUtm,
        device_type: isMobile() ? 'mweb' : 'dweb'
      }
    });

    // Trigger events that are in queue
    if (this.eventQueue.length > 0) {
      this.eventQueue.forEach(eventData => {
        this.pushEvents(eventData);
      });

      this.eventQueue = [];
    }
  }

  pushEvents(data) {
    const isOnboardingQDefined =
      window.rzpQ && window.rzpQ.now() && window.rzpQ.now().onbr();

    if (!isOnboardingQDefined) {
      this.eventQueue.push(data);
      return;
    }

    let properties = Object.assign(
      {},
      this.commonProperties,
      data.properties || {}
    );

    transformLJEventsForSegment(
      data.event_name + ' ' + data.event_type,
      properties,
      data.toCleverTap || false
    );

    switch (data.event_type) {
      case 'initiated':
        window.rzpQ
          .now()
          .onbr()
          .initiated(data.event_name);
        break;
      case 'success':
        window.rzpQ
          .now()
          .onbr()
          .success(data.event_name);
        break;
      case 'viewed':
        window.rzpQ
          .now()
          .onbr()
          .viewed(data.event_name);
        break;
      case 'clicked':
        window.rzpQ
          .now()
          .onbr()
          .clicked(data.event_name);
        break;
    }
    window.rzpQ.now().push(properties);
  }
  initTrack() {
    const queues = this.trackQueue;
    this.trackQueue = [];
    queues.forEach(eventData => {
      this.track(eventData);
    });
  }
  getCommonProperties(location, screen, timestamp) {
    const clientId = getCookie('clientId');
    const ljProperties = getLjEventProps();
    const sessionInfo = getSessionInfo();
    const connection = navigator.connection;
    const network_type = (connection && connection.type) || null;
    const eventTimestamp = timestamp || new Date().toISOString();
    const browser_details = razorpayAnalytics.utils.getBrowserDetails();

    let attrib_utm = null;
    try {
      attrib_utm = JSON.parse(getCookie('lastAttribUtm'));
    } catch (e) {}

    const commonProperties = Object.assign(
      {},
      {
        mode: 'live',
        referring_url: document.referrer,
        url: document.location.href,
        utm_params: razorpayAnalytics.utils.getLandingParams(),
        screen: screen || document.location.href,
        location,
        eventTimestamp,
        user_agent: navigator.userAgent,
        connection: navigator.connection,
        referrer: document.referrer,
        title: document.title,
        page: window.location.pathname,
        first_utm: ljProperties.firstUtm,
        last_utm: ljProperties.lastUtm,
        client_id: clientId,
        clientId,
        common_session_id: sessionInfo.commonSessionId,
        is_landing_page_session: sessionInfo.isLandingPageSession,
        experiment_ID: sessionStorage.getItem('experimentId') || '',
        is_landing_page_user: ljProperties.isLandingPageUser,
        first_page: ljProperties.firstPage,
        new_user: ljProperties.isNewUser,
        version: 1,
        first_page_user: ljProperties.isFirstPageUser,
        first_page_session: ljProperties.isFirstPageSession,
        device_type: browser_details.device,
        network_type,
        attrib_utm,
        variant: 'original',
        gclid: getCookie('gclid'),
        midExists: getCookie('midExists') || false,
        x_mid_exist: getCookie('x_mid_exist') || false
      },
      browser_details
    );

    return commonProperties;
  }
  // Events as per Object action framework taxonamy. Ref: frontend-universe analytics sevice(https://github.com/razorpay/frontend-universe/blob/master/packages/universe-utils/src/analytics/makeAnalytics.js#L78)
  track({
    objectName,
    actionName,
    screen,
    location,
    properties = {},
    eventTimestamp
  }) {
    const israzorpayAnalyticsLoaded = !!window.razorpayAnalytics;

    if (!israzorpayAnalyticsLoaded) {
      this.trackQueue.push({
        objectName,
        actionName,
        screen,
        location,
        properties,
        eventTimestamp
      });
      return;
    }
    if (this.trackQueue.length) {
      this.initTrack();
    }

    const trackProperties = Object.assign(
      this.getCommonProperties(location, screen, eventTimestamp),
      properties
    );

    const eventName = toTitleCase(`${objectName} ${actionName}`);

    emitSegment(eventName, trackProperties);
    $.ajax({
      method: 'post',
      url: 'https://lumberjack.razorpay.com/v1/track',
      data: {
        key: 'ezGnY9wN3IjNwgDM3MDMygzNwMTO04yy',
        data: btoa(
          JSON.stringify({
            mode: 'live',
            context: {
              client_id: trackProperties.client_id,
              referrer: trackProperties.referrer,
              user_agent: trackProperties.user_agent,
              network: {
                type: trackProperties.connection_type
              },
              device: trackProperties.device,
              screen_width: trackProperties.screen_width,
              screen_height: trackProperties.screen_height,
              os: trackProperties.os,
              browser: trackProperties.browser
            },
            events: [
              {
                event_type: 'onboarding-events',
                event: eventName,
                event_version: 'v1',
                timestamp: new Date(trackProperties.eventTimestamp).getTime(),
                properties: trackProperties
              }
            ]
          })
        )
      }
    });
  }
}

export const ACTIONS = {
  CLICKED: 'clicked',
  RENDERED: 'rendered'
};

export default new LJEvents();

/**
 * Smooth scroll to sections in the same page.
 * Source: https://css-tricks.com/snippets/jquery/smooth-scrolling/#comment-197181
 */
$('a.smooth-scroll[href*=\\#]:not([href=\\#])').click(function() {
  if (
    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') ||
    location.hostname == this.hostname
  ) {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    var topOffset = this.getAttribute('data-smooth-scroll-offset') || 0;
    if (target.length) {
      $('html,body').animate(
        {
          scrollTop: target.offset().top - Number(topOffset)
        },
        700
      );

      var evtObj = lytics.getAttribsFromEl(this);
      if (evtObj.trigger && evtObj.trigger === 'click') {
        window.rzpAnalytics(evtObj);
      }

      return false;
    }
  }
});

import { nodeListToArray } from './util';
const videos = nodeListToArray(
  document.querySelectorAll('.js-video-container')
);

videos.forEach(videoContainer => {
  videoContainer.getElementsByTagName('video')[0];
  const buttonsContainer = document.createElement('div');
  const play = document.createElement('div');
  buttonsContainer.classList.add('buttons-container');
  play.classList.add('play');
  buttonsContainer.appendChild(play);
  videoContainer.appendChild(buttonsContainer);
});

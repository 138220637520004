import { scrollTo } from './util';

var onResize, onScroll;
var $secnav = $('.secnav');

if ($secnav.length) {
  let elementTop, isFixed;
  let $tabsHead = $('.secnav .secnav-head');

  onResize = function() {
    // 2 is for minute boundary enhancement
    elementTop = $('.secnav').offset().top + 2;
    onScroll();
  };

  onScroll = function() {
    // XOR
    if (pageYOffset < elementTop ? isFixed : !isFixed) {
      $tabsHead[(isFixed = !isFixed) ? 'addClass' : 'removeClass']('fixed');
    }
  };
}

export var onResize;
export var onScroll;

import { qs, qsa } from './util';
import { selectMenuItem, trackSegment, isMobile } from './segment-utils';
import lj from '../lumberjack';

lj.setupLJ();
//Navbar Events
qs('#nav-signin') &&
  qs('#nav-signin').addEventListener('click', e => {
    trackSegment(e, 'Sign In CTA Clicked', {
      target_url: e.currentTarget.href
    });
  });

// Resources Menu Option
selectMenuItem('Resources') &&
  selectMenuItem('Resources').addEventListener('mouseover', e => {
    trackSegment(e, 'hover on resources in top menu bar');
  });

selectMenuItem('Resources') &&
  selectMenuItem('Resources').addEventListener('click', e => {
    trackSegment(e, 'click on resources in top menu bar');
  });

// Products Menu Option
selectMenuItem('Products') &&
  selectMenuItem('Products').addEventListener('mouseover', e => {
    trackSegment(e, 'hover on products in top menu bar');
  });

selectMenuItem('Products') &&
  selectMenuItem('Products').addEventListener('click', e => {
    trackSegment(e, 'click on products in top menu bar');
  });

// Banking Menu Option
selectMenuItem('Banking') &&
  selectMenuItem('Banking').addEventListener('mouseover', e => {
    trackSegment(e, 'hover on Banking in top menu bar');
  });

selectMenuItem('Banking') &&
  selectMenuItem('Banking').addEventListener('click', e => {
    trackSegment(e, 'click on develop in top menu bar');
  });

// Develop Menu Option -> Docs Section Menu bar
selectMenuItem('Docs') &&
  selectMenuItem('Docs').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'view Razorpay documentation', {
      target_url: e.currentTarget.parentNode.parentNode.href
    });
  });

selectMenuItem('APIReference') &&
  selectMenuItem('APIReference').addEventListener('click', e => {
    trackSegment(e, 'click on API reference CTA', {
      target_url: e.currentTarget.parentNode.href
    });
  });

selectMenuItem('Integration') &&
  selectMenuItem('Integration').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on integrations CTA', {
      target_url: e.currentTarget.parentNode.href
    });
  });

// Support Menu Option
selectMenuItem('Support') &&
  selectMenuItem('Support').addEventListener('mouseover', e => {
    trackSegment(e, 'hover on support in top menu bar');
  });

selectMenuItem('Support') &&
  selectMenuItem('Support').addEventListener('click', e => {
    trackSegment(e, 'click on support in top menu bar');
  });

// Pricing Menu Option
selectMenuItem('Pricing') &&
  selectMenuItem('Pricing').addEventListener('mouseover', e => {
    trackSegment(e, 'hover on pricing in top menu bar');
  });

selectMenuItem('Pricing') &&
  selectMenuItem('Pricing').addEventListener('click', e => {
    trackSegment(e, 'click on pricing in top menu bar');
  });

//Navbar Product Pages Instrumentation
selectMenuItem('Capital') &&
  selectMenuItem('Capital').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on capital CTA in menubar', {
      target_page: e.currentTarget.parentNode.parentNode.href
    });
    location.href = e.currentTarget.parentNode.parentNode.href;
  });

selectMenuItem('PG') &&
  selectMenuItem('PG').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on payment gateway CTA in menubar', {
      target_page: e.currentTarget.parentNode.parentNode.href
    });
    location.href = e.currentTarget.parentNode.parentNode.href;
  });

selectMenuItem('PL') &&
  selectMenuItem('PL').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on payment links CTA in menubar', {
      target_page: e.currentTarget.parentNode.parentNode.href
    });
    location.href = e.currentTarget.parentNode.parentNode.href;
  });

selectMenuItem('PB') &&
  selectMenuItem('PB').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on payment button CTA in menubar', {
      target_page: e.currentTarget.parentNode.parentNode.href
    });
    location.href = e.currentTarget.parentNode.parentNode.href;
  });

selectMenuItem('PP') &&
  selectMenuItem('PP').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on payment pages CTA in menubar', {
      target_page: e.currentTarget.parentNode.parentNode.href
    });
    location.href = e.currentTarget.parentNode.parentNode.href;
  });

selectMenuItem('Subscriptions') &&
  selectMenuItem('Subscriptions').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on subscription CTA in menubar', {
      target_page: e.currentTarget.parentNode.parentNode.href
    });
    location.href = e.currentTarget.parentNode.parentNode.href;
  });

selectMenuItem('SmartCollect') &&
  selectMenuItem('SmartCollect').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on smart collect CTA in menubar', {
      target_page: e.currentTarget.parentNode.parentNode.href
    });
    location.href = e.currentTarget.parentNode.parentNode.href;
  });

selectMenuItem('UPIAuto') &&
  selectMenuItem('UPIAuto').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on UPI Autopay CTA in menubar', {
      target_page: e.currentTarget.parentNode.href
    });
    location.href = e.currentTarget.parentNode.parentNode.href;
  });

selectMenuItem('Freelance') &&
  selectMenuItem('Freelance').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on freelance unregistered CTA in menubar', {
      target_page: e.currentTarget.parentNode.href
    });
    location.href = e.currentTarget.parentNode.href;
  });

selectMenuItem('Route') &&
  selectMenuItem('Route').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on route CTA in menubar', {
      target_page: e.currentTarget.parentNode.href
    });
    location.href = e.currentTarget.parentNode.parentNode.href;
  });

selectMenuItem('CRED') &&
  selectMenuItem('CRED').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on CRED Pay CTA in menubar', {
      target_page: e.currentTarget.parentNode.parentNode.href
    });
    location.href = e.currentTarget.parentNode.parentNode.href;
  });

selectMenuItem('Invoices') &&
  selectMenuItem('Invoices').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on invoices CTA in menubar', {
      target_page: e.currentTarget.parentNode.parentNode.href
    });
    location.href = e.currentTarget.parentNode.parentNode.href;
  });

selectMenuItem('PreCOD') &&
  selectMenuItem('PreCOD').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on prepay COD CTA in menubar', {
      target_page: e.currentTarget.parentNode.parentNode.href
    });
    location.href = e.currentTarget.parentNode.parentNode.href;
  });

selectMenuItem('UPI') &&
  selectMenuItem('UPI').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on UPI CTA in menubar', {
      target_page: e.currentTarget.parentNode.href
    });
    location.href = e.currentTarget.parentNode.parentNode.href;
  });

selectMenuItem('Affordability') &&
  selectMenuItem('Affordability').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on affordability CTA in menubar', {
      target_page: e.currentTarget.parentNode.href
    });
    location.href = e.currentTarget.parentNode.parentNode.href;
  });

selectMenuItem('EPOS') &&
  selectMenuItem('EPOS').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on epos CTA in menubar', {
      target_page: e.currentTarget.parentNode.href
    });
    location.href = e.currentTarget.parentNode.parentNode.href;
  });

selectMenuItem('SettlementGuide') &&
  selectMenuItem('SettlementGuide').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on Settlement guide CTA in menubar', {
      target_page: e.currentTarget.parentNode.href
    });
    location.href = e.currentTarget.parentNode.href;
  });

selectMenuItem('KnowlegdeBase') &&
  selectMenuItem('KnowlegdeBase').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'click on Knowlegde base CTA in menubar', {
      target_page: e.currentTarget.parentNode.href
    });
    location.href = e.currentTarget.parentNode.href;
  });

// Random Events
qs('#fake-chat-icon') &&
  qs('#fake-chat-icon').addEventListener('click', e => {
    trackSegment(e, 'user clicks on chatbot (web)');
  });

qs('#fake-chat-icon-rx') &&
  qs('#fake-chat-icon-rx').addEventListener('click', e => {
    lj.pushEvents({
      event_name: 'RX_Chatbot',
      event_type: 'clicked',
      properties: { device_type: isMobile() ? 'mweb' : 'dweb' }
    });
  });

//Payments App Events
qs('#payments-app .top-banner-details .cta.nomob') &&
  qs('#payments-app .top-banner-details .cta.nomob').addEventListener(
    'click',
    e => {
      trackSegment(e, 'Viewed App Download QR', {
        platform: 'Desktop',
        section: 'First Fold',
        button_label: 'Download the App now'
      });
    }
  );

qs('#payments-app .top-banner-details .cta.mob') &&
  qs('#payments-app .top-banner-details .cta.mob').addEventListener(
    'click',
    e => {
      trackSegment(e, 'Viewed Store Product Page', {
        platform: 'Mobile',
        section: 'First Fold',
        button_label: 'Download the App now'
      });
    }
  );

qs('#payments-app .top-banner-details .note') &&
  qs('#payments-app .top-banner-details .note').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'Viewed App Tech Docs', {
      section: 'First Fold',
      target_url: e.currentTarget.childNodes[0].href
    });
    window.open(e.currentTarget.childNodes[0].href);
  });

qs('#payments-app #business .cta.nomob') &&
  qs('#payments-app #business .cta.nomob').addEventListener('click', e => {
    trackSegment(e, 'Viewed App Download QR', {
      platform: 'Desktop',
      section: 'Fourth Fold',
      button_label: 'Download the App now'
    });
  });

qs('#payments-app #business .cta.mob') &&
  qs('#payments-app #business .cta.mob').addEventListener('click', e => {
    trackSegment(e, 'Viewed Store Product Page', {
      platform: 'Mobile',
      section: 'Fourth Fold',
      button_label: 'Download the App now'
    });
  });

qs('#payments-app #checkout .cta.nomob') &&
  qs('#payments-app #checkout .cta.nomob').addEventListener('click', e => {
    trackSegment(e, 'Viewed App Download QR', {
      platform: 'Desktop',
      section: 'Fifth Fold',
      button_label: 'Get the app now'
    });
  });

qs('#payments-app #checkout .cta.mob') &&
  qs('#payments-app #checkout .cta.mob').addEventListener('click', e => {
    trackSegment(e, 'Viewed Store Product Page', {
      platform: 'Mobile',
      section: 'Fifth Fold',
      button_label: 'Get the app now'
    });
  });

qs('#payments-app .pricing-card-pg a.btn.pri.nomob') &&
  qs('#payments-app .pricing-card-pg a.btn.pri.nomob').addEventListener(
    'click',
    e => {
      trackSegment(e, 'Viewed App Download QR', {
        platform: 'Desktop',
        section: 'Pricing Fold',
        button_label: 'Get Started'
      });
    }
  );

qs('#payments-app .pricing-card-pg a.btn.pri.mob') &&
  qs('#payments-app .pricing-card-pg a.btn.pri.mob').addEventListener(
    'click',
    e => {
      trackSegment(e, 'Viewed Store Product Page', {
        platform: 'Mobile',
        section: 'Pricing Fold',
        button_label: 'Get Started'
      });
    }
  );

qs('#payments-app .pricing-card-pg a.link.has-arrow.mob') &&
  qs('#payments-app .pricing-card-pg a.link.has-arrow.nomob').addEventListener(
    'click',
    e => {
      trackSegment(e, 'Viewed App Download QR', {
        platform: 'Desktop',
        section: 'Pricing Fold',
        button_label: 'Get the App'
      });
    }
  );

qs('#payments-app .pricing-card-pg a.link.has-arrow.nomob') &&
  qs('#payments-app .pricing-card-pg a.link.has-arrow.mob').addEventListener(
    'click',
    e => {
      trackSegment(e, 'Viewed Store Product Page', {
        platform: 'Mobile',
        section: 'Pricing Fold',
        button_label: 'Get the App'
      });
    }
  );

qs('#payments-app #checkout-points .read-more') &&
  qs('#payments-app #checkout-points .read-more').addEventListener(
    'click',
    e => {
      trackSegment(e, 'Click on read more to complete the sentence', {
        section: 'checkout points fold'
      });
    }
  );

qs('#payments-app #checkout-points .more') &&
  qs('#payments-app #checkout-points .more a').addEventListener('click', e => {
    e.preventDefault();
    trackSegment(e, 'Viewed Security LP', {
      section: 'checkout points fold'
    });
    window.open(e.currentTarget.href);
  });

//PA, PL, PG Events
qs('#payments-app .CTA.PG-CTA a') &&
  qs('#payments-app .CTA.PG-CTA a').addEventListener('click', e => {
    trackSegment(e, 'PG Mobile App Landing Page Clicked', {
      section: 'MApp Fold 5'
    });
  });

qs('#payments-app .download-app.PG-DA .mob .store a.play') &&
  qs('#payments-app .download-app.PG-DA .mob .store a.play').addEventListener(
    'click',
    e => {
      trackSegment(e, 'Android Product Page Clicked', {
        section: 'MApp Fold 5'
      });
    }
  );

qs('#payments-app .download-app.PG-DA .mob .store a.apple') &&
  qs('#payments-app .download-app.PG-DA .mob .store a.apple').addEventListener(
    'click',
    e => {
      trackSegment(e, 'iOS Product Page Clicked', {
        section: 'MApp Fold 5'
      });
    }
  );

qs('#payments-app .download-app.PG-DA .nomob .store a.play') &&
  qs('#payments-app .download-app.PG-DA .nomob .store a.play').addEventListener(
    'click',
    e => {
      trackSegment(e, 'Android Product Page QR Viewed', {
        section: 'MApp Fold 5'
      });
    }
  );

qs('#payments-app .download-app.PG-DA .nomob .store a.apple') &&
  qs(
    '#payments-app .download-app.PG-DA .nomob .store a.apple'
  ).addEventListener('click', e => {
    trackSegment(e, 'iOS Product Page QR Viewed', {
      section: 'MApp Fold 5'
    });
  });

qs('#payments-app .CTA.PL-CTA a') &&
  qs('#payments-app .CTA.PL-CTA a').addEventListener('click', e => {
    trackSegment(e, 'PG Mobile App Landing Page Clicked', {
      section: 'MApp Fold 3'
    });
  });

qs('#payments-app .download-app.PL-DA .mob .store a.play') &&
  qs('#payments-app .download-app.PL-DA .mob .store a.play').addEventListener(
    'click',
    e => {
      trackSegment(e, 'Android Product Page Clicked', {
        section: 'MApp Fold 3'
      });
    }
  );

qs('#payments-app .download-app.PL-DA .mob .store a.apple') &&
  qs('#payments-app .download-app.PL-DA .mob .store a.apple').addEventListener(
    'click',
    e => {
      trackSegment(e, 'iOS Product Page Clicked', {
        section: 'MApp Fold 3'
      });
    }
  );

qs('#payments-app .download-app.PL-DA .nomob .store a.play') &&
  qs('#payments-app .download-app.PL-DA .nomob .store a.play').addEventListener(
    'click',
    e => {
      trackSegment(e, 'Android Product Page QR Viewed', {
        section: 'MApp Fold 3'
      });
    }
  );

qs('#payments-app .download-app.PL-DA .nomob .store a.apple') &&
  qs(
    '#payments-app .download-app.PL-DA .nomob .store a.apple'
  ).addEventListener('click', e => {
    trackSegment(e, 'iOS Product Page QR Viewed', {
      section: 'MApp Fold 3'
    });
  });

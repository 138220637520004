let daysEls = document.querySelectorAll('#timer .days .number');
let hoursEls = document.querySelectorAll('#timer .hours .number');
let minutesEls = document.querySelectorAll('#timer .minutes .number');
let secondsEls = document.querySelectorAll('#timer .seconds .number');
let timerEl = document.querySelector('#timer');
let startTimeFromEl, endTimeFromEl, startTime, deadline;

if (timerEl) {
  startTimeFromEl = timerEl.dataset.startTime;
  endTimeFromEl = timerEl.dataset.endTime;
}
if (startTimeFromEl) {
  startTime = new Date(startTimeFromEl).getTime();
} else {
  startTime = new Date('Sep 30, 2021 00:00:01').getTime();
}
if (endTimeFromEl) {
  deadline = new Date(endTimeFromEl).getTime();
} else {
  deadline = new Date('Nov 20, 2021 23:59:00').getTime();
}

let timeLeft, days, hours, minutes, seconds;

let interval = setInterval(() => {
  timeLeft = deadline - new Date().getTime();
  days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

  days = days.toString();
  hours = hours.toString();
  minutes = minutes.toString();
  seconds = seconds.toString();

  Array.from(daysEls).forEach(function(el) {
    el.innerHTML = days.length === 1 ? '0' + days : days;
  });
  Array.from(hoursEls).forEach(function(el) {
    el.innerHTML = hours.length === 1 ? '0' + hours : hours;
  });
  Array.from(minutesEls).forEach(function(el) {
    el.innerHTML = minutes.length === 1 ? '0' + minutes : minutes;
  });
  Array.from(secondsEls).forEach(function(el) {
    el.innerHTML = seconds.length === 1 ? '0' + seconds : seconds;
  });

  if (timeLeft < 0) {
    clearInterval(interval);
  }
}, 1000);

/**
 * To automatically hide/show the banner based on start/end date
 * on pages where banner is enabled
 * banner is hidden if query param banner=hidden present
 */
if (document.body.classList.contains('has-topbar')) {
  let topBarContainer = document.querySelector('#topbar-container');

  const urlSearchParams = new URLSearchParams(window.location.search);
  const bannerQuery = urlSearchParams.get('banner');
  const noHideQuery = bannerQuery !== 'hidden';
  if (
    startTime - new Date().getTime() <= 0 &&
    deadline - new Date().getTime() > 0 &&
    noHideQuery
  ) {
    topBarContainer.classList.remove('hide');
    document.body.classList.add('body-top-padding');
  } else {
    topBarContainer.classList.add('hide');
    document.body.classList.remove('body-top-padding');
  }
}

import { goog_report_conversion } from 'marketing';
import { nodeListToArray, getPath, qs, qsa } from './modules/util';
import lj from './lumberjack';
import { isMobile } from './segment/segment-utils';

let title = document.getElementsByTagName('title')[0].innerText;
let productVersion = 1.1;
let midExists =
  (window.razorpayAnalytics &&
    razorpayAnalytics.utils.getCookie('midExists')) ||
  false;

(function() {
  //
  // Navigation Analytics
  //
  const NavigationAnalytics = {
    // User unintentionally hovered above nav items.
    nullHover(item) {
      if (isMobile()) return;
      item = initcap(item);
      this.log('User unintentionally hovered above "%s"', item);

      this.send({
        action: 'Hover - Nav Category (null)',
        label: item
      });
    },
    // User clicked on nav item while it is open.
    navItemClick(item) {
      if (isMobile()) return;
      item = initcap(item);
      this.log('User clicked on "%s" while it is open.', item);

      this.send({
        action: 'Click - Nav Category',
        label: item
      });
    },
    // User stayed on X section for T seconds.
    userStayedOnMenu(x, time, direction) {
      if (isMobile()) return;
      if (time < 0.5) return; // Don't track interactions of less than 0.5 second.
      if (!direction) return;
      x = initcap(x);
      direction = initcap(direction);
      this.log(
        'User stayed on "%s" section for %f seconds. (%s)',
        x,
        time,
        direction
      );
      this.send({
        action: 'Hover - Nav Box (out direction)',
        label: x + ' - ' + direction,
        value: time
      });
    },
    // User clicks on menu item.
    userClicksOnMenu(menu) {
      this.stopRecord(menu, 'inside');
      menu = initcap(menu);
      this.log('User clicked a link in %s menu.', menu);
      this.sendTagRecording('navigation_item_clicked');
      // Tracked by lytics.
    },
    calculateDirection(mouseEvent, bounds) {
      const { clientX, clientY } = mouseEvent;
      if (clientX <= bounds.left) return 'left';
      if (clientX >= bounds.right) return 'right';
      if (clientY <= bounds.top) return 'top';
      if (clientY >= bounds.bottom) return 'bottom';
      return 'inside';
    },
    _items: ['products', 'develop', 'resources'],
    _timings: {},
    _listeners: {},
    _entrySemaphore: {},
    enteredMenu(menu) {
      this._entrySemaphore[menu] = true;
    },
    exitedMenu(menu) {
      this._entrySemaphore[menu] = false;
    },
    didEnter(menu) {
      return this._entrySemaphore[menu] || false;
    },
    startRecord(menu) {
      if (isMobile()) return;
      if (this.isRecording(menu)) {
        return;
      }
      this.stopRecord(menu);
      this.enteredMenu(menu);
      const ref = this;
      this._timings[menu] = 0;
      this._listeners[menu] = setInterval(function() {
        ref._timings[menu] += 0.5;
      }, 500);
      this.sendTagRecording('navigation_category_hovered');
    },
    isRecording(menu) {
      return this._listeners[menu] && this._timings[menu] > 1;
    },
    stopRecord(menu, direction) {
      if (isMobile()) return;
      if (menu && menu.length) {
        const listenerId = this._listeners[menu];
        if (listenerId) {
          clearInterval(listenerId);
        }

        const time = this._timings[menu];
        if (time) {
          this.userStayedOnMenu(menu, time, direction);
        }

        this._listeners[menu] = null;
        this._timings[menu] = null;
      } else {
        this._items.forEach(item => stopRecord(item));
      }
    },
    log() {
      // Log only on localhost.
      if (location.host.indexOf('localhost') > -1) {
        console.info.apply(console, arguments);
      }
    },
    send(data) {
      if (!window.rzpAnalytics) return;
      this.log(data);
      window.rzpAnalytics({
        eventCategory: data.category || 'Website - Navigation',
        eventAction: data.action || undefined,
        eventLabel: data.label || undefined,
        eventValue: data.value || undefined
      });
    },
    sendTagRecording(tag) {
      if (typeof window.hj === 'function') {
        window.hj('trigger', tag);
        window.hj('tagRecording', [tag]);
      }
    }
  };
  // ANALYTICS SETUP

  // TODO: After optimize experiment, rename this function to setupAnalytics and remove the old function
  function setupNewNavAnalytics() {
    // ANALYTICS FOR NEW NAVBAR.

    // Analytics for Nav Link Clicks
    const navItems = document.querySelectorAll('#nav .nav-link');
    navItems.forEach(navItem => {
      navItem.addEventListener('click', () => {
        lj.pushEvents({
          event_name: 'website.top_nav_link',
          event_type: 'clicked',
          properties: {
            // For data-lake, Remove v1.1 from label since we anyway pass version number
            label: navItem.dataset.lyticsLabel.replace(' v1.1', ''),
            title,
            midExists,
            version: productVersion,
            device_type: isMobile() ? 'mweb' : 'dweb'
          }
        });
      });
    });

    // Analytics for Menu Hover
    const navMenuItems = document.querySelectorAll(
      'nav .container ul .nav-category'
    );

    navMenuItems.forEach(navMenuItem => {
      navMenuItem.addEventListener('mouseover', item => {
        lj.pushEvents({
          event_name: 'website.top_nav_menu',
          event_type: 'viewed',
          properties: {
            label: navMenuItem.innerText,
            title,
            midExists,
            version: productVersion,
            device_type: isMobile() ? 'mweb' : 'dweb'
          }
        });
      });
    });
  }

  //
  // Navigation
  //
  function Navigation(selector) {
    this.nav = document.querySelector(selector);
    if (!this.nav) return;
    this.items = nodeListToArray(document.querySelectorAll('.nav-menu'));
    this.list = document.querySelector(selector + ' ul');
    this.line = document.querySelector(selector + ' .nav-line');
    this.itemBounds = [];
    this.lineTransition = this.line ? this.line.style.transition : '';
    this.lineAnimation = this.line ? this.line.style.animation : '';
    this.mobileBackdrop = document.querySelector('.backdrop.mob');
    this.hamMenu = document.querySelector('#ham-menu');
    this.hadCursor = false;

    this.classes = {
      OPEN_CLASS: 'opened',
      ACTIVE_CLASS: 'active',
      INACTIVE_CLASS: 'inactive',
      MOB_OPEN_CLASS: 'open-nav',
      BUTTON_INPUT_CLASS: 'active'
    };

    // IE11 Fixes
    if (isIE11() && !isMobile()) {
      const nav1 = document.querySelector('.nav1 .sub');
      const nav1col1 = document.querySelector('.nav1 .column:nth-child(1)');
      const nav1col2 = document.querySelector('.nav1 .column:nth-child(2)');
      const nav2 = document.querySelector('.nav2 .sub');
      const nav3 = document.querySelector('.nav3 .sub');
      if (nav1 && nav2 && nav3) {
        nav1.style.width = '1080px';
        nav2.style.width = '545px';
        nav3.style.width = '474px';
        nav1col1.style.width = nav1col2.style.width = '440px';
      }
    }
  }
  // Is the item being hovered?
  function currentHoverItem(item) {
    if (item instanceof HTMLElement) {
      return !!item.querySelector(':hover');
    }
    return !!document.querySelector(item + ':hover');
  }

  function isMobile() {
    return window.innerWidth < 1024;
  }

  // JS doesn't have a capitalize function. #JustJavascriptThings.
  function initcap(string) {
    if (string && string.length) {
      return string.charAt(0).toUpperCase() + string.substr(1);
    }
    return '';
  }

  // Wait for 200ms before calling onhover callback, if cursor out, fail.
  function waitForCommittedHover(target, onCommit, onRefrain, time) {
    if (!target) return;

    const COMMIT_TIME = time || 200; // ms

    let cancel = false;
    target.addEventListener('mouseleave', function onMouseLeave() {
      cancel = true;
      target.removeEventListener('mouseleave', onMouseLeave);
    });

    setTimeout(function() {
      if (cancel) {
        if (typeof onRefrain === 'function') {
          onRefrain();
        }
      } else {
        if (typeof onCommit === 'function') {
          onCommit();
        }
      }
    }, COMMIT_TIME);
  }

  Navigation.prototype = {
    init: function() {
      const ref = this;
      const { items, hamMenu, mobileBackdrop, animateLine, hasParent } = this;
      const {
        ACTIVE_CLASS,
        OPEN_CLASS,
        MOB_OPEN_CLASS,
        INACTIVE_CLASS
      } = this.classes;
      const nav = this.nav;

      // Prevent 'sub' FOUC when the page is loading.
      setTimeout(function() {
        removeClass(nav, 'loading');
        addClass(nav, 'loaded');

        nodeListToArray(nav.querySelectorAll('.sub')).forEach(
          sub => (sub.style.animationDuration = '')
        );
      }, 100);

      this.calculateBounds();

      const setupEvents = item => {
        const openMenu = function(target, clickedOnAnchor) {
          addClass(nav, OPEN_CLASS);

          if (!isMobile()) {
            // Desktop Interactions.
            addClass(item, ACTIVE_CLASS);
            removeClass(item, INACTIVE_CLASS);

            NavigationAnalytics.startRecord(item.dataset.navItemId);
            animateLine.call(ref, target, nav.hadCursor);
          } else {
            // Mobile Interactions.
            if (hasClass(target, ACTIVE_CLASS)) {
              if (!clickedOnAnchor) {
                removeClass(target, ACTIVE_CLASS);
                addClass(target, INACTIVE_CLASS);

                target.style.maxHeight = '';
              }
              return;
            }
            const active = nav.querySelector('li.' + ACTIVE_CLASS);
            if (active) {
              removeClass(active, ACTIVE_CLASS);
              addClass(target, INACTIVE_CLASS);

              active.style.maxHeight = '';
            }
            const sub = target.querySelector('.sub');
            if (sub) {
              const maxHeight = 64 + 64 + sub.getBoundingClientRect().height;
              target.style.maxHeight = maxHeight + 'px';
            }

            addClass(target, ACTIVE_CLASS);
            removeClass(target, INACTIVE_CLASS);
          }
        };

        item.addEventListener('click', function(mouseEvent) {
          // Was the click on an anchor?
          let clickedOnAnchor = false;
          const path = mouseEvent.path || getPath(mouseEvent.target);
          clickedOnAnchor =
            path
              .filter(e => e instanceof HTMLElement)
              .map(e => e.tagName.toLowerCase())
              .indexOf('a') > -1;
          if (isMobile()) {
            openMenu(mouseEvent.currentTarget, clickedOnAnchor);
            return;
          }
          if (clickedOnAnchor) {
            NavigationAnalytics.userClicksOnMenu(item.dataset.navItemId);
          }
        });

        item.querySelector('span').addEventListener('click', function() {
          NavigationAnalytics.navItemClick(item.dataset.navItemId);
        });

        item.addEventListener('mouseenter', function() {
          if (isMobile()) return;
          if (nav.hadCursor) {
            // User is moving from one category to another.
            openMenu(item);
          } else {
            waitForCommittedHover(
              item,
              function onCommit() {
                animateLine.call(ref, item, false);
                openMenu(item);
              },
              function onRefrain() {
                NavigationAnalytics.nullHover(item.dataset.navItemId);
              }
            );
          }
        });

        item.addEventListener('mouseleave', function(mouseEvent) {
          if (isMobile()) return;

          nav.hadCursor = currentHoverItem('.nav-menu');
          removeClass(nav, OPEN_CLASS);

          const target = mouseEvent.currentTarget;
          if (hasParent(target, item)) {
            return;
          }

          removeClass(target, ACTIVE_CLASS);
          addClass(target, INACTIVE_CLASS);

          setTimeout(function() {
            if (!hasClass(target, ACTIVE_CLASS)) {
              const bounds = item.querySelector('.sub')
                ? item.querySelector('.sub').getBoundingClientRect()
                : '';
              let direction = NavigationAnalytics.calculateDirection(
                mouseEvent,
                bounds
              );
              // Did the user move from one category to another?
              const onAnotherCategory = nav.querySelector('.nav-menu.active');
              if (onAnotherCategory && onAnotherCategory !== target) {
                direction = onAnotherCategory.dataset.navItemId;
              }
              NavigationAnalytics.stopRecord(
                target.dataset.navItemId,
                direction
              );
            }
          }, 150);
        });
      };

      items.forEach(setupEvents);

      // Events for Explore Buttons in Mobile Navbar
      function closeExploreMenu() {
        document.querySelectorAll('.nav-menu.active').forEach(navMenu => {
          navMenu.classList.add('inactive');
          navMenu.classList.remove('active');
        });
      }

      document
        .querySelectorAll('#nav .explore-button')
        .forEach(exploreButtonEl =>
          exploreButtonEl.addEventListener('click', e => {
            e.stopPropagation();
            closeExploreMenu();

            document
              .querySelector(exploreButtonEl.dataset.targetNav)
              .classList.add('active');
            document
              .querySelector(exploreButtonEl.dataset.targetNav)
              .classList.remove('inactive');

            document
              .querySelector(exploreButtonEl.dataset.targetNav)
              .querySelector('.sub').scrollTop = 0;
          })
        );

      if (hamMenu) {
        hamMenu.addEventListener('click', function() {
          const body = document.body;

          if (hasClass(body, MOB_OPEN_CLASS)) {
            removeClass(body, MOB_OPEN_CLASS);
          } else {
            closeExploreMenu();
            addClass(body, MOB_OPEN_CLASS);
            mobileBackdrop.addEventListener('mouseup', function mouseUp(e) {
              removeClass(body, MOB_OPEN_CLASS);
              mobileBackdrop.removeEventListener('mouseup', mouseUp);
            });
          }
        });
      }
      this.setupSignInEvents();
    },
    hasParent(target, parent) {
      if (!target) return;
      while (target.parentElement !== document.body) {
        if (target.parentElement === parent) {
          return true;
        }
        target = target.parentElement;
      }
      return false;
    },
    calculateBounds: function() {
      const { itemBounds, items } = this;
      itemBounds.length = 0;
      items.forEach(item => {
        const id = item.dataset.navItemId;
        itemBounds[id] = item.getBoundingClientRect();
      });
    },
    animateLine: function(item, skipAnimation) {
      const { line, lineTransition, lineAnimation, list } = this;
      if (line) {
        if (!skipAnimation) {
          line.style.transition = 'none';
          line.style.animation = 'none';
        }

        const leftOffset = list.getBoundingClientRect().left;
        const bounds = item.getBoundingClientRect();
        const BOUNDARY = 32 + 4; // padding + margin

        line.style.width = bounds.width - BOUNDARY + 'px';
        line.style.opacity = 1;
        line.style.left = bounds.left - leftOffset + BOUNDARY / 2 + 'px';

        if (!skipAnimation) {
          setTimeout(() => {
            line.style.transition = lineTransition;
            line.style.animation = lineAnimation;
          }, 0);
        }
      }
    },
    onResize() {
      this.calculateBounds();
    },
    setupSignInEvents() {
      const signIn = document.querySelector('#nav-signin');
      const navMobTop = document.querySelector('#nav-signin-top-nav');
      if (signIn) {
        signIn.addEventListener('click', () => {
          lj.pushEvents({
            event_name: 'login.website_login',
            event_type: 'initiated',
            properties: {
              position:
                window.innerWidth < 1024
                  ? 'Hamburger Menu - Mobile'
                  : 'Top Nav - Desktop'
            }
          });
        });
      }
      if (navMobTop) {
        navMobTop.addEventListener('click', () => {
          lj.pushEvents({
            event_name: 'login.website_login',
            event_type: 'initiated',
            properties: {
              position: 'Top Bar - Mobile'
            }
          });
        });
      }
    }
  };

  function hasClass(elem, lookupClass) {
    return (' ' + elem.className + ' ').indexOf(' ' + lookupClass + ' ') > -1;
  }

  function addClass(el, className) {
    if (el.classList) el.classList.add(className);
    else if (!hasClass(el, className)) el.className += ' ' + className;
  }

  function removeClass(el, className) {
    if (el.classList) el.classList.remove(className);
    else if (hasClass(el, className)) {
      const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
      el.className = el.className.replace(reg, ' ');
    }
  }

  function isIE11() {
    return !!window.MSInputMethodContext && !!document.documentMode;
  }

  //
  // animate-on-scroll
  //
  // Adds an 'appear-in' class to every element
  // that has an 'animate-on-scroll' class (when visible in viewport).
  //

  const animateOnScrollTargets = nodeListToArray(
    document.querySelectorAll('.animate-on-scroll')
  );

  if (animateOnScrollTargets.length) {
    // Make the target visible when it is 300px away.
    const TOP_OFFSET = 300; // px
    const APPEAR_IN_CLASS = 'appear-in';
    window.addEventListener('scroll', () => {
      animateOnScrollTargets.forEach(target => {
        const bounds = target.getBoundingClientRect();
        if (bounds.top - TOP_OFFSET < 0) {
          target.classList.add(APPEAR_IN_CLASS);
        }
      });
    });
  }

  function initNavbar() {
    const nav = new Navigation('#nav');
    setupNewNavAnalytics();
    setTimeout(() => {
      // This hack removes the expanded menu flash in mobile while loading the navbar
      if (document.querySelector('#nav')) {
        document.querySelector('#nav').style.transition = '0.3s transform';
      }
    }, 500);

    if (!nav.nav) {
      return;
    }

    // Fire navbar displayed event
    lj.pushEvents({
      event_name: 'website.top_nav_bar',
      event_type: 'viewed',
      properties: {
        version: productVersion
      },
      device_type: isMobile() ? 'mweb' : 'dweb'
    });
    nav.init();

    $(window).on('resize', function() {
      nav.onResize();
    });
  }

  $(window).ready(function() {
    initNavbar();
  });

  $('.subscribe-form')
    .submit(function(e) {
      e.preventDefault();
      var $form = $(this);
      var $button = $form.find('button');
      var origText = $button.html();
      var $emailInput = $(this).find('input[name="email"]');
      var $action = $(this).attr('data-action');
      var email = $emailInput.val();
      $button
        .prop('disabled', 'true')
        .html('Please Wait...')
        .removeClass('action-success');

      $(this)
        .find('input[name="ref_url"]')
        .remove();
      $(this).append(
        '<input type="hidden" name="ref_url" value="' +
          window.location.href +
          '" /> '
      );

      $.ajax({
        method: 'get',
        url: $action,
        data: $(this).serialize(),
        complete: function(r) {
          $button.prop('disabled', false).html(origText);
          if (!r.responseJSON) {
            // submissionInfo(parent, 'There was an error, please check your network connection.');
          } else if (r.responseJSON.status === 'success') {
            $button.html(
              origText
                .replace('Subscribed', 'Subscribe')
                .replace('Subscribe', 'Subscribed')
            );
            $button
              .find('i')
              .removeClass('i-arrow-foward')
              .addClass('i-tick');
            $button.addClass('action-success');
            $emailInput.attr('placeholder', email);
            $form[0].reset();
          } else {
            // submissionInfo(parent, 'There was an error in submitting your form');
          }
        }
      });
    })
    .not('.focus')
    .find('input')
    .focus(function() {
      $(this)
        .parent()
        .addClass('focus');
    })
    .blur(function() {
      $(this)
        .parent()
        .removeClass('focus');
    });
})();

//
// Social Icons "sticky" behaviour events
//

const sticky = document.querySelector('.social-share-stacked');
if (sticky && !nav.mobile) {
  // sticky behaviour only in desktop
  const startElement = sticky.parentElement || document.body;
  const endElementSelector =
    sticky.getAttribute('data-sticky-end-element') || 'body';
  const endElement = document.querySelector(endElementSelector);
  const TOP_OFFSET = 72;
  const FIXED_TOP_CLASS = 'fixed-top';
  window.addEventListener('scroll', () => {
    const start = startElement.getBoundingClientRect().top;
    const end = endElement.getBoundingClientRect().bottom;
    const stickyHeight = sticky.getBoundingClientRect().height;
    const stickyBottom = end - stickyHeight - TOP_OFFSET;
    if (start <= TOP_OFFSET) {
      sticky.classList.add(FIXED_TOP_CLASS);
    } else {
      sticky.classList.remove(FIXED_TOP_CLASS);
      sticky.style.top = '';
    }
    if (stickyBottom < 0) {
      sticky.style.top = stickyBottom + TOP_OFFSET + 'px';
    }
  });
}

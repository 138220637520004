import { scrollTo } from './util';

function showContent(targetSelector) {
  var $content = $('#content-' + targetSelector.slice(1));
  $content
    .siblings('.btn')
    .hide()
    .prop('offsetWidth');
  $content.siblings('.always-active').removeClass('active');
  var $prev = $content.siblings('.active').removeClass('active');

  setTimeout(function() {
    $content.show().prop('offsetWidth');
    $content.addClass('active');
  }, 200);
  setTimeout(function() {
    $content.siblings('.btn').show();
    $prev.hide();
  }, 300);
}

function toggleDiv(targetId){
  $(targetId).addClass('active').siblings().removeClass('active');
}

export function switchTab() {
  var $this = $(this).addClass('active');
  var targetSelector = $this.data('content');
  var targetId = $this.data('id');
  var siblings = $this.siblings();

  siblings.filter('.active').removeClass('active');
  racerPosition($this);

  targetSelector && showContent(targetSelector);
  targetId && toggleDiv(targetId);
}

export function racerPosition($anchor) {
  $anchor.siblings('.tab-racer').css({
    width: $anchor.prop('offsetWidth'),
    left: $anchor.prop('offsetLeft')
  });
}

export function onResize() {
  racerPosition($('.secnav .active'));
}

var $tabs = $('.tab-title').click(function(e) {
  switchTab.call(this);
});

$('.tabs-accordian .tab-header').click(function(e) {
  var currentElement = $(this);
  var prevElement = $(this).siblings('.active');
  if (!prevElement.length)
    //If same tab is clicked return
    return;
  var currentElementoffset = currentElement.offset().top;
  var prevElementHeight = prevElement.innerHeight();
  var collapsedHeight =
    prevElementHeight - prevElement.children('.content.mob').innerHeight();
  switchTab.call(this);
  if (prevElement.offset().top > currentElementoffset) {
    prevElementHeight = 0;
    collapsedHeight = 0;
  }
  if (!($(window).width() >= 1024)) {
    //If not desktop
    scrollTo(currentElementoffset - prevElementHeight + collapsedHeight - 62);
  }
});

$('.secnav .tab-title').click(function() {
  var isFirstChild = $(this).is(':first-child');
  if (isFirstChild) {
    history && history.pushState('', '', location.pathname + location.search);
  } else {
    location.hash = $(this).data('content');
  }

  scrollTo($('.secnav').offset().top + 2);
});

function applyInitialHash() {
  if (location.hash) {
    $(
      '.secnav .tab-title:not(:first-child)[data-content="' +
        location.hash +
        '"]'
    )
      .click()
      .siblings()
      .filter('.initial-active')
      .removeClass('initial-active');
  }
}

applyInitialHash();
onhashchange = applyInitialHash;

setTimeout(function() {
  applyInitialHash();
  $tabs
    .filter('.initial-active')
    .each(switchTab)
    .removeClass('initial-active');
}, 200);

/* For checkout features */
$('.f-tab-title').click(function(e) {
  var $this = $(this);
  var targetSelector = $this.data('content');

  $this
    .parents('.f-tabs')
    .find('.f-tab-title')
    .filter('.active')
    .removeClass('active');

  $this.addClass('active');

  showContent(targetSelector);
});

$('.payment-container-mobile .tabs .tab').click(function() {
  $('.payment-container-mobile .tabs .tab').map((index, node) =>
    node.classList.remove('active')
  );
  !$(this)[0].classList.contains('.active') &&
    $(this)[0].classList.add('active');
});

$('#products.tabs .tab-title').click(function() {
  $(`#products.tabs .light:nth-child(${$(this).index() + 1})`)
    .addClass('active')
    .siblings('.light')
    .removeClass('active');
});

$('#products.tabs .light').click(function() {
  $(`#products.tabs .tab-title:nth-child(${$(this).index() + 1})`).click();
});

$('#products.tabs').on('keyup', function(e) {
  e = e || window.event;
  switch (e.which || e.keyCode) {
    case 37:
      $('#products.tabs .tab-title.active')
        .prev('.tab-title')
        .click();
      break;
    case 39:
      $('#products.tabs .tab-title.active')
        .next('.tab-title')
        .click();
  }
});

$('.faqs-tab-title.nomob').click(function(e) {
  switchTab.call(this);
});

$('.faqs-tab-title.mob').click(function(e) {
  if ($(this).hasClass('active')) {
    $(this).removeClass('active');
    return;
  }
  switchTab.call(this);
});

$('.anchor-tab-title').click(function() {
  var selectedTab = $(this).addClass('active');
  var siblings = selectedTab.siblings();
  var href = $(this).attr('href');
  if(href === '#developers') {
    $('#overview').hide();
    $('#developers').show();
  } else if (href === '#overview') {
    $('#developers').hide();
    $('#overview').show();
  }

  siblings.filter('.active').removeClass('active');
  racerPosition(selectedTab);
});

(function() {
  function onHashChanged() {
    var hash = location.hash;
    var selector = '.tabs-content' + hash;
    var el = document.querySelector(selector);
    var topOffset = 48;

    if (el) {
      $('html,body').animate(
        {
          scrollTop: $(el).offset().top - Number(topOffset)
        },
        200
      );
    }
  }

  window.addEventListener('hashchange', onHashChanged);

  if (location.hash.slice(1)) {
    onHashChanged();
  }
})();

import { open } from './modules/modal';

function initGrievances() {
  var form = $('#grievances_form');
  var clientOrCustomer;

  function setRequired(forWhom) {
    var enable = forWhom;
    var disable = enable === 'merchant' ? 'customer' : 'merchant';

    var enableContainer = '.section.' + enable;
    var disableContainer = '.section.' + disable;

    var enableFor = $(enableContainer + ' input:not(.optional)')
      .toArray()
      .concat($(enableContainer + ' select').toArray())
      .concat($('input.' + enable).toArray());
    var disableFor = $(disableContainer + ' input')
      .toArray()
      .concat($(disableContainer + ' select').toArray())
      .concat($('input.' + disable).toArray());

    for (var i = 0; i < enableFor.length; i++) {
      $(enableFor[i]).attr('required', true);
    }

    for (var i = 0; i < disableFor.length; i++) {
      $(disableFor[i]).removeAttr('required');
    }
  }

  form.on('submit', function(e) {
    e.preventDefault();
    var $form = $(this);
    var $button = $form.find('button');
    var origText = $button.html();
    $button.prop('disabled', 'true').html('Please Wait...');

    var data = $(this).serialize();

    $.ajax({
      method: this.method,
      url: `https://hooks.zapier.com/hooks/catch/1088429/${data.indexOf('client_or_customer=customer') ? 'kdkedd' : 'krj0on'}/`,
      data,
      complete: function(r) {
        $button.prop('disabled', false).html(origText);
        if (!r.responseJSON) {
          // submissionInfo(parent, 'There was an error, please check your network connection.');
        } else if (r.responseJSON.status === 'success') {
          $form[0].reset();
          $('#content').addClass('hidden');
          $('#ticket_ref_container').addClass('hidden');
          $('#ticket_ref_container input').removeAttr('required');
          if (clientOrCustomer === 'client') {
            openModal('#grievance-merchant-modal');
          } else {
            openModal('#grievance-customer-modal');
          }
          $('html, body').animate({ scrollTop: 0 }, 'fast');
        } else {
          // submissionInfo(parent, 'There was an error in submitting your form');
        }
      }
    });
  });

  $('input[name="client_or_customer"]').on('change', function(e) {
    $('#content').removeClass('hidden');
    var val = $(this).val();
    clientOrCustomer = val;
    if (val === 'client') {
      form.removeClass('show-customer').addClass('show-merchant');
      setRequired('merchant');
    } else if (val === 'customer') {
      form.removeClass('show-merchant').addClass('show-customer');
      setRequired('customer');
    }
  });

  $('input[name="contacted_support"]').on('change', function(e) {
    var val = $(this).val();

    if (val === 'yes') {
      $('#ticket_ref_container').removeClass('hidden');
      $('#ticket_ref_container input').attr('required', true);
    } else if (val === 'no') {
      $('#ticket_ref_container').addClass('hidden');
      $('#ticket_ref_container input').removeAttr('required');
    }
  });

  $('.info').on('click', function(e) {
    var target = $(this).data('target');
    if (target) {
      $(target).removeClass('hidden');
    }
  });
}

if ($('.grievances') && $('.grievances').length) {
  initGrievances();
}

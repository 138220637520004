$('#demo-receipt-modal').appendTo('body');

const selectorsToUseUTMWith = {
  '#enterprise-modal': true,
  '#contact-modal': true
};

function addAnalyticsDataToForm($element) {
  const rzp_utm =
    (razorpayAnalytics &&
      razorpayAnalytics.utils.readUTMFromSessionStorage('rzp_utm')) ||
    {};

  Object.keys(rzp_utm).forEach(key => {
    const value = rzp_utm[key];
    let input = $element.find(`input[name=${key}]`);

    if (input.length === 1) {
      input = $(input[0]);
      input.val(value);
    } else {
      input = document.createElement('input');

      input.name = key;
      input.type = 'hidden';
      input.value = value;

      $element.append(input);
    }
  });
}

export function open(selector, selectorId = '', callbackForHide) {
  var $element = $(selector);
  $('body').append('<div class="overlay" id="modal-overlay"></div>');
  // Helpninja
  $(document.body).addClass('no-helpninja-mob');
  $(document.body).addClass('no-scroll'); // disable scroll on the body
  selectorId && $(selector + ' form').attr('id', selectorId);
  if (selectorsToUseUTMWith[selector]) {
    addAnalyticsDataToForm($element.find(`form`));
  }

  var $overlay = $('#modal-overlay');
  $overlay.fadeIn(300);
  $element
    .show(300, function() {
      $element.addClass('show');
      if (history && history.pushState) {
        history.pushState('modal-open', {});
        window.addEventListener(
          'popstate',
          e => {
            if (e.state === 'modal-open') {
              hide($element);
            }
          },
          { once: true }
        );
      }
    })
    .on('click', function(event) {
      var target = event.target;
      if (
        target === $element[0] ||
        $(target).closest($(this).find('.close')).length
      ) {
        $element.removeClass('success');
        hide($element, $overlay, callbackForHide);
      }
    });
}

export function hide($element, $overlay, callback) {
  $overlay = $overlay || $('#modal-overlay');
  callback = callback || function() {};

  $overlay.fadeOut('fast', function() {
    $(this).remove();
    callback();
  });
  $element
    .off('click')
    .removeClass('show')
    .hide(300);

  if (history && history.popState) {
    history.popState('modal-open');
  }
  $(document.body).removeClass('no-scroll');

  // Helpninja
  setTimeout(function() {
    $(document.body).removeClass('no-helpninja-mob');
  }, 300);

  if ($element[0].id === 'tech-hiring-modal') {
    let jobId = $element.find('.close').data('type');
    window.rzpAnalytics({
      eventAction: 'Close - Job Description',
      eventLabel: jobsDb[jobId] ? jobsDb[jobId].title : undefined
    });
  }
}

/**
 *
 * @param { function } functionToBeExecuted - script which needs to be executed
 * @param { number } delay - delay function execution for $delay milliseconds
 */
function executeAfterDelay(functionToBeExecuted, delay) {
  if (typeof functionToBeExecuted === 'function')
    setTimeout(() => {
      functionToBeExecuted();
    }, delay);
}

/**
 *
 * @param { function } functionToBeExecuted - what needs to be executed once cpu is idle
 * @param { number } delay - once cpu is idle keep it free for 3 seconds after that start the execution of script
 * if requestIdleCallback is not available script after 7 sec (current cpu idle) of page load
 */
export function executeWhenIdle(...args) {
  const trigger = delay => args.forEach(func => executeAfterDelay(func, delay));

  if (window.requestIdleCallback) {
    window.requestIdleCallback(() => trigger(3000));
  } else {
    trigger(7000);
  }
}

import { debounce, isInViewport } from 'modules/util';

function Video(videoContainer, isAutoPlay) {
  this.videoContainer = videoContainer;
  this.muteButton = this.videoContainer.getElementsByClassName('mute-btn')[0];
  this.video = this.videoContainer.getElementsByTagName('video')[0];
  this.pausedManually = false;
  this.isAutoPlay = isAutoPlay === undefined ? true : isAutoPlay;

  this.init = function() {
    this.muteButton && this.addMuter();
    this.addPlayPause();
    if (this.isAutoPlay) {
      this.autoPlayVideo();
      window.addEventListener('scroll', debounce(this.autoPlayVideo, 100));
    }
  };

  this.addMuter = function() {
    this.muteButton.addEventListener('click', e => {
      e.stopPropagation();
      if (this.video.muted) {
        this.video.muted = false;
        this.videoContainer.classList.remove('muted');
      } else {
        this.video.muted = true;
        this.videoContainer.classList.add('muted');
      }
    });
  };

  this.autoPlayVideo = () => {
    if (this.pausedManually) return;
    isInViewport(this.video) ? this.video.play() : this.video.pause();
  };

  this.addPlayPause = function() {
    if (!this.isAutoPlay) {
      this.videoContainer.classList.add('paused');
      this.videoContainer.classList.add('show-poster');
    }
    this.videoContainer.addEventListener('click', () => {
      if (this.video.paused == true) {
        this.video.play();
        this.pausedManually = false;
      } else {
        this.pausedManually = true;
        this.video.pause();
      }
    });

    this.video.addEventListener('ended', () => {
      this.videoContainer.classList.remove('paused');
      this.videoContainer.classList.add('ended');
    });

    this.video.addEventListener('pause', () => {
      this.videoContainer.classList.add('paused');
    });

    this.video.addEventListener('play', () => {
      this.videoContainer.classList.remove('show-poster');
      this.videoContainer.classList.remove('ended');
      this.videoContainer.classList.remove('paused');
    });
  };
}

export default Video;

import lj from '../lumberjack';
import {
  debounce,
  isElementXPercentInViewport,
  isTouchEnabled,
  tapElement
} from './util';
import { open as openModal } from './modal';

export const neoInstrumentation = pageID => {
  let oldPricingSection, neoPricingSection;
  if (pageID === 'payment-botton') {
    oldPricingSection = $('section#pricing.pricing-card');
    neoPricingSection = $('section#pricing.pricing-card.neo-pricing');
  } else {
    oldPricingSection = $('section.pricing-card-pg#pricing-section');
    neoPricingSection = $(
      'section.pricing-card-pg.neo-pricing#pricing-section'
    );
  }
  oldPricingSection.addClass('hide');
  neoPricingSection.removeClass('hide');

  let title = '',
    midExists = false,
    version = 2.1;
  if (window.razorpayAnalytics) {
    midExists = razorpayAnalytics.utils.getCookie('midExists') || false;
  }
  const properties = {
    title,
    midExists,
    version
  };

  // User clicks on "signup"
  const signupBTN = $('.pc-signup-btn');
  if (signupBTN.length) {
    signupBTN.each(function() {
      $(this).on('click', function() {
        const pricingType = $(this).attr('id'),
          eventProperties = properties,
          scrollPosition = getWindowCurrentScrollPercentage();
        eventProperties.title = document.getElementsByTagName(
          'title'
        )[0].innerText;
        eventProperties.pricing_type =
          pricingType == 'neo-signup' ? 'Neo' : 'Classic';
        eventProperties.location = scrollPosition / 100;

        lj.pushEvents({
          event_name: 'website.signup',
          event_type: 'clicked',
          properties: eventProperties
        });
      });
    });
  }

  // User Clicks on the "How does this work" button that opens a popup
  const hdtw = $('#hdtw');
  if (hdtw.length) {
    hdtw.on('click', () => {
      const eventProperties = properties;
      eventProperties.title = document.getElementsByTagName(
        'title'
      )[0].innerText;

      lj.pushEvents({
        event_name: 'website.neopricing_popup',
        event_type: 'clicked',
        properties: eventProperties
      });
    });
  }

  // Event is fired when at least 75% of the Pricing Section in product pages is in viewport
  const pricingCard = document.querySelector(
    '.neo-pricing .columns.left-illustration'
  );
  let pcEventFired = false;
  if (pricingCard)
    window.addEventListener('scroll', debounce(viewportEvent, 200));
  function viewportEvent() {
    if (!pcEventFired && isElementXPercentInViewport(pricingCard, 75)) {
      lj.pushEvents({
        event_name: 'website.product_page_pricing_section',
        event_type: 'viewed',
        properties: properties
      });
      pcEventFired = true;
    }
  }

  // Event is fired when user scrolls over a 40, 60, 80, 100 percent of the page
  let pageScroll40, pageScroll60, pageScroll80, pageScroll100;
  pageScroll40 = pageScroll60 = pageScroll80 = pageScroll100 = false;
  function getWindowCurrentScrollPercentage() {
    const scrollTop = window.scrollY;
    const docHeight = document.body.offsetHeight;
    const winHeight = window.innerHeight;
    const scrollPercent = (scrollTop * 100) / (docHeight - winHeight);
    return scrollPercent;
  }
  const pageScrollEvent = () => {
    if (!pageScroll40 && getWindowCurrentScrollPercentage() >= 40) {
      const eventProperties = properties;
      eventProperties.scroll_percentage = 40;

      lj.pushEvents({
        event_name: 'website.page_scroll',
        event_type: 'viewed',
        properties: eventProperties
      });
      pageScroll40 = true;
    }

    if (!pageScroll60 && getWindowCurrentScrollPercentage() >= 60) {
      const eventProperties = properties;
      eventProperties.scroll_percentage = 60;

      lj.pushEvents({
        event_name: 'website.page_scroll',
        event_type: 'viewed',
        properties: eventProperties
      });
      pageScroll60 = true;
    }

    if (!pageScroll80 && getWindowCurrentScrollPercentage() >= 80) {
      const eventProperties = properties;
      eventProperties.scroll_percentage = 80;

      lj.pushEvents({
        event_name: 'website.page_scroll',
        event_type: 'viewed',
        properties: eventProperties
      });
      pageScroll80 = true;
    }

    if (!pageScroll100 && getWindowCurrentScrollPercentage() >= 100) {
      const eventProperties = properties;
      eventProperties.scroll_percentage = 100;

      lj.pushEvents({
        event_name: 'website.page_scroll',
        event_type: 'viewed',
        properties: eventProperties
      });
      pageScroll100 = true;
    }
  };
  window.addEventListener('scroll', debounce(pageScrollEvent, 200));

  // User clicks on the icon for the next testimonial to be shown
  const testimonials = $('#testimonials');
  const prevArrow = testimonials.find('.prev-arrow');
  const nextArrow = testimonials.find('.next-arrow');
  const testimonialsList = testimonials.find('.item');
  const totalTestimonails = testimonialsList ? testimonialsList.length : null;
  let previous_testimonial = null,
    current_testimonial = 1;
  if (
    testimonials.length &&
    prevArrow.length &&
    nextArrow.length &&
    totalTestimonails.length
  ) {
    nextArrow.on('click', () => {
      current_testimonial =
        current_testimonial === totalTestimonails
          ? current_testimonial
          : current_testimonial + 1;
      previous_testimonial = current_testimonial - 1;

      const eventProperties = properties;
      eventProperties.previous_testimonial = previous_testimonial;
      eventProperties.current_testimonial = current_testimonial;

      lj.pushEvents({
        event_name: 'website.testimonial',
        event_type: 'clicked',
        properties: eventProperties
      });
    });
    prevArrow.on('click', () => {
      current_testimonial =
        current_testimonial === 1
          ? current_testimonial
          : current_testimonial - 1;
      previous_testimonial = current_testimonial + 1;

      const eventProperties = properties;
      eventProperties.previous_testimonial = previous_testimonial;
      eventProperties.current_testimonial = current_testimonial;

      lj.pushEvents({
        event_name: 'website.testimonial',
        event_type: 'clicked',
        properties: eventProperties
      });
    });
  }

  if (!isTouchEnabled()) {
    // When Tooltip is shown to the user when they hover over(desktop website) or tap(mobile website)the information icon
    const razorpayXText = $('.rxca-ribbon .column.v-center > .razorpayx-text');
    const tooltip = razorpayXText.next();
    if (razorpayXText.length && tooltip.length) {
      razorpayXText.on('mouseover', () => {
        tooltip.addClass('visible');
        const eventProperties = properties;
        eventProperties.tooltip_type = 'razorpay_x_current_account';

        lj.pushEvents({
          event_name: 'website.tooltip',
          event_type: 'viewed',
          properties: eventProperties
        });
      });

      razorpayXText.on('mouseleave', () => {
        tooltip.removeClass('visible');
      });

      tooltip.on('mouseover', () => {
        tooltip.addClass('visible');
      });

      tooltip.on('mouseleave', () => {
        tooltip.removeClass('visible');
      });
    }
  } else {
    // Opening Razorpay X Current Account tooltip as modal for small screens
    const rxca = document.querySelector(
      '.rxca-ribbon .column.v-center > .razorpayx-text'
    );
    if (rxca) {
      tapElement(rxca, () => openModal('#razorpayx-ca-info-modal'));
    }
  }

  if (!isTouchEnabled()) {
    const tncs = $('.double-heading .column .tnc');
    const tncTooltips = tncs.next();

    if (tncs.length && tncTooltips.length) {
      tncs.each(function() {
        $(this).on('mouseover', function() {
          const tncTooltip = $(this).next();
          tncTooltip.addClass('visible');

          const eventProperties = properties;
          eventProperties.tooltip_type = 'classic';

          lj.pushEvents({
            event_name: 'website.tooltip',
            event_type: 'viewed',
            properties: eventProperties
          });
        });

        $(this).on('mouseleave', function() {
          const tncTooltip = $(this).next();
          tncTooltip.removeClass('visible');
        });
      });

      tncTooltips.each(function() {
        $(this).on('mouseover', function() {
          $(this).addClass('visible');
        });

        $(this).on('mouseleave', function() {
          $(this).removeClass('visible');
        });
      });
    }
  } else {
    const tncs = document.querySelectorAll('.double-heading .column .tnc');
    if (tncs.length) {
      tncs.forEach(element => {
        element.addEventListener('click', () =>
          openModal('#tnc-tooltip-modal')
        );
      });
    }
  }

  // User clicks on the "Know More" link in the tooltip for Razorpay X Current Account
  const ttKnowMore = $('.tooltip a');
  if (ttKnowMore.length) {
    ttKnowMore.on('click', () => {
      const eventProperties = properties;
      eventProperties.pricing_type = 'neo';

      lj.pushEvents({
        event_name: 'website.ca_tooltip_know_more',
        event_type: 'clicked',
        properties: eventProperties
      });
    });
  }

  if (hdtw.length) {
    hdtw.on('click', () => {
      openModal('#pricing-neo-plan-modal');
    });
  }

  // Open and close FAQ questions
  const faqList = $('.modal#pricing-neo-plan-modal .modal-contents .faqs .faq');
  if (faqList.length) {
    faqList.each(function(index) {
      $(this).on('click', function() {
        faqList.each(function() {
          const question = $(this).find('.q');
          const answer = $(this).find('.a');
          question.removeClass('open');
          answer.addClass('hide');
        });

        const question = $(this).find('.q');
        const answer = $(this).find('.a');

        if (!question.hasClass('open')) {
          const eventProperties = properties;
          eventProperties.faq_question = index + 1;

          lj.pushEvents({
            event_name: 'website.neopricing_popup_faq',
            event_type: 'clicked',
            properties: eventProperties
          });
        }

        question.toggleClass('open');
        answer.toggleClass('hide');
      });
    });
  }
};

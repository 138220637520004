import lj from './lumberjack';
import { debounce } from './util';

export const newPricingInstrumentation = () => {
  let title = '',
    midExists = false,
    version = 2.1;
  if (window.razorpayAnalytics) {
    midExists = razorpayAnalytics.utils.getCookie('midExists') || false;
  }
  const properties = {
    title,
    midExists,
    version
  };

  // User clicks on Signup button
  const neoPricingSignupBTNs = $('.signup-btn');
  neoPricingSignupBTNs.each(function() {
    $(this).on('click', function() {
      const eventProperties = properties,
        scrollPosition = getWindowCurrentScrollPercentage();
      eventProperties.title = document.getElementsByTagName(
        'title'
      )[0].innerText;
      eventProperties.pricing_type = $(this).data('pricing_type');
      eventProperties.location = scrollPosition;
      eventProperties.button_stack_order = $(this).data('button_stack_order');

      lj.pushEvents({
        event_name: 'website.signup',
        event_type: 'clicked',
        properties: eventProperties
      });
    });
  });

  // User Clicks on the "How does this work" button that opens a popup
  const howDoesThisWork = $(
    'section#main .column.neo-pricing .mob-expand-view a, section#pricing-details.nomob .column.neo-pricing a'
  );
  if (howDoesThisWork.length) {
    howDoesThisWork.each(function() {
      $(this).on('click', () => {
        const eventProperties = properties;
        eventProperties.title = document.getElementsByTagName(
          'title'
        )[0].innerText;

        lj.pushEvents({
          event_name: 'website.neopricing_popup',
          event_type: 'clicked',
          properties: eventProperties
        });
      });
    });
  }

  // Event is fired when user scrolls over a 40, 60, 80, 100 percent of the page
  let pageScroll40, pageScroll60, pageScroll80, pageScroll100;
  pageScroll40 = pageScroll60 = pageScroll80 = pageScroll100 = false;
  function getWindowCurrentScrollPercentage() {
    const scrollTop = window.scrollY;
    const docHeight = document.body.offsetHeight;
    const winHeight = window.innerHeight;
    const scrollPercent = (scrollTop * 100) / (docHeight - winHeight);
    return scrollPercent;
  }
  const pageScrollEvent = () => {
    if (!pageScroll40 && getWindowCurrentScrollPercentage() >= 40) {
      const eventProperties = properties;
      eventProperties.scroll_percentage = 40;

      lj.pushEvents({
        event_name: 'website.page_scroll',
        event_type: 'viewed',
        properties: eventProperties
      });
      pageScroll40 = true;
    }

    if (!pageScroll60 && getWindowCurrentScrollPercentage() >= 60) {
      const eventProperties = properties;
      eventProperties.scroll_percentage = 60;

      lj.pushEvents({
        event_name: 'website.page_scroll',
        event_type: 'viewed',
        properties: eventProperties
      });
      pageScroll60 = true;
    }

    if (!pageScroll80 && getWindowCurrentScrollPercentage() >= 80) {
      const eventProperties = properties;
      eventProperties.scroll_percentage = 80;

      lj.pushEvents({
        event_name: 'website.page_scroll',
        event_type: 'viewed',
        properties: eventProperties
      });
      pageScroll80 = true;
    }

    if (!pageScroll100 && getWindowCurrentScrollPercentage() >= 100) {
      const eventProperties = properties;
      eventProperties.scroll_percentage = 100;

      lj.pushEvents({
        event_name: 'website.page_scroll',
        event_type: 'viewed',
        properties: eventProperties
      });
      pageScroll100 = true;
    }
  };
  window.addEventListener('scroll', debounce(pageScrollEvent, 200));

  // User clicks on the icon for the next testimonial to be shown
  const testimonials = $('#testimonials');
  const prevArrow = testimonials.find('.prev-arrow');
  const nextArrow = testimonials.find('.next-arrow');
  const testimonialsList = testimonials.find('.item');
  const totalTestimonails = testimonialsList ? testimonialsList.length : null;
  let previous_testimonial = null,
    current_testimonial = 1;
  if (
    testimonials.length &&
    prevArrow.length &&
    nextArrow.length &&
    totalTestimonails.length
  ) {
    nextArrow.on('click', () => {
      current_testimonial =
        current_testimonial === totalTestimonails
          ? current_testimonial
          : current_testimonial + 1;
      previous_testimonial = current_testimonial - 1;

      const eventProperties = properties;
      eventProperties.previous_testimonial = previous_testimonial;
      eventProperties.current_testimonial = current_testimonial;
      lj.pushEvents({
        event_name: 'website.testimonial',
        event_type: 'clicked',
        properties: eventProperties
      });
    });
    prevArrow.on('click', () => {
      current_testimonial =
        current_testimonial === 1
          ? current_testimonial
          : current_testimonial - 1;
      previous_testimonial = current_testimonial + 1;

      const eventProperties = properties;
      eventProperties.previous_testimonial = previous_testimonial;
      eventProperties.current_testimonial = current_testimonial;
      lj.pushEvents({
        event_name: 'website.testimonial',
        event_type: 'clicked',
        properties: eventProperties
      });
    });
  }

  // User clicks on the "Know More" link in the tooltip for Razorpay X Current Account
  const knowMoreRXCATooltip = $('.card#current-account .popup a');
  if (knowMoreRXCATooltip.length) {
    knowMoreRXCATooltip.on('click', () => {
      const eventProperties = properties;
      eventProperties.pricing_type = 'neo';

      lj.pushEvents({
        event_name: 'website.ca_tooltip_know_more',
        event_type: 'clicked',
        properties: eventProperties
      });
    });
  }

  // When Tooltip is shown to the user when they hover over(desktop website) or tap(mobile website)the information icon
  const popupTriggers = $(
    '#pricing-details .container .columns .column.first-col .card .text-container .head'
  );
  if (popupTriggers.length) {
    popupTriggers.each(function() {
      $(this).on('mouseover', function() {
        const popupTriggerText = $(this).text();
        const eventProperties = properties;
        eventProperties.tooltip_type = popupTriggerText.includes('Domestic')
          ? 'domestic'
          : popupTriggerText.includes('International')
          ? 'international'
          : 'razorpay_x_current_account';

        lj.pushEvents({
          event_name: 'website.tootltip',
          event_type: 'viewed',
          properties: eventProperties
        });
      });
    });
  }

  // FAQ questions instrumentation
  const faqList = $('#new-pricing-faqs .faq');
  if (faqList.length) {
    faqList.each(function(index) {
      $(this).on('click', function() {
        const question = $(this).find('.q');

        if (!question.hasClass('open')) {
          const eventProperties = properties;
          eventProperties.faq_question = index + 1;

          lj.pushEvents({
            event_name: 'website.neopricing_popup_faq',
            event_type: 'clicked',
            properties: eventProperties
          });
        }
      });
    });
  }
};

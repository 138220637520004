import { nodeListToArray, debounce } from './util';
import Video from 'modules/video-module';

const allCarousels = nodeListToArray(
  document.querySelectorAll('.carousel-new')
);
const allVideos = nodeListToArray(
  document.querySelectorAll('.carousel-new .video-container')
);

window.addEventListener('load', () => {
  allCarousels.forEach(carousel => {
    let carouselInstance = new Carouseler(carousel);
    carouselInstance.init();
  });

  allVideos.forEach(video => {
    let videoInstance = new Video(video, false);
    videoInstance.init();
  });
});

// add learn more functionality
var allCarouselsContents = nodeListToArray(
  document.querySelectorAll('.carousel-content')
);
allCarouselsContents.forEach(carouselContent => {
  carouselContent.addEventListener('click', e => {
    if (e.target.classList.contains('learn')) {
      e.target
        .closest('.testimonial-new-content')
        .classList.remove('show-heading');
      e.target.closest('.testimonial-new-content').classList.add('show-desc');
    }
  });
});

function Carouseler(carousel) {
  this.carouselItems = carousel.getElementsByClassName('carousel-item');
  this.scroller = carousel.getElementsByClassName('carousel-content')[0];
  this.next = carousel.getElementsByClassName('next-button')[0];
  this.prev = carousel.getElementsByClassName('prev-button')[0];
  this.dotsSection = document.createElement('div');
  this.itemWidth = this.scroller.offsetWidth; // since only one item in one view
  this.allDots = null; // updated in addDots()

  this.init = () => {
    this.addDots();
    this.addNavListener();
    this.addStateListener(); // state: update dot and next/prev with active/disable states.
    this.addResizeListener();
  };

  this.addResizeListener = () => {
    window.addEventListener(
      'resize',
      debounce(() => {
        this.itemWidth = this.scroller.offsetWidth;
        // this.nextElement();
      }),
      1000
    );
  };

  this.nextElement = (steps = 1) => {
    const currentActive = Math.round(this.scroller.scrollLeft / this.itemWidth);
    this.scroller.scroll({ left: this.itemWidth * (currentActive + steps) });
  };

  this.prevElement = (steps = 1) => {
    const currentActive = Math.round(this.scroller.scrollLeft / this.itemWidth);
    this.scroller.scroll({ left: this.itemWidth * (currentActive - steps) });
  };

  this.addStateListener = () => {
    const _set = () => {
      const currentActive = Math.round(
        this.scroller.scrollLeft / this.itemWidth
      );
      if (
        this.scroller.scrollLeft + this.itemWidth >=
        this.itemWidth * this.allDots.length - 2
      ) {
        this.next.classList.add('disable');
        this.prev.classList.remove('disable');
      } else if (this.scroller.scrollLeft <= 2) {
        this.prev.classList.add('disable');
        this.next.classList.remove('disable');
      } else {
        this.prev.classList.remove('disable');
        this.next.classList.remove('disable');
      }

      this.allDots.forEach(dot => {
        dot.classList.remove('active');
      });
      this.allDots[currentActive].classList.add('active');
    };
    _set();
    this.scroller.addEventListener('scroll', debounce(_set, 100));
  };

  this.dotNavigation = dotIndex => {
    let activeDot = 0;
    nodeListToArray(this.dotsSection.querySelectorAll('.dot')).forEach(
      (dot, i) => {
        if (dot.classList.contains('active')) {
          activeDot = i;
        }
      }
    );
    if (activeDot <= dotIndex) {
      this.nextElement(dotIndex - activeDot);
    } else if (activeDot > dotIndex) {
      this.prevElement(activeDot - dotIndex);
    }
  };

  this.addDots = () => {
    this.dotsSection.classList.add('carousel-dots');
    for (let i = 1; i <= this.carouselItems.length; i++) {
      let dot = document.createElement('div');
      dot.classList.add('dot');
      this.dotsSection.appendChild(dot);
    }
    carousel.appendChild(this.dotsSection);
    this.allDots = nodeListToArray(
      carousel.querySelectorAll('.carousel-dots .dot')
    );
    this.allDots.forEach((dot, i) => {
      dot.addEventListener('click', () => {
        this.dotNavigation(i);
      });
    });
  };

  this.addNavListener = () => {
    this.next.addEventListener('click', () => {
      this.nextElement();
    });
    this.prev.addEventListener('click', () => {
      this.prevElement();
    });
  };
}

export function triggerWebVitalLogs() {
  var script = document.createElement('script');
  script.src = 'https://unpkg.com/web-vitals';
  script.onload = function() {
    // Logging inside textarea so that I can test on actual mobile
    const webVitalsReportEl = document.createElement('textarea');
    webVitalsReportEl.width = window.innerWidth;
    webVitalsReportEl.height = '300px';
    document.body.append(webVitalsReportEl);

    const vitalParams = [];

    function logReport(report) {
      console.log(report);

      if (report.entries.length > 0 && report.name === 'LCP') {
        const newReport = {
          entries: report.entries.map(entry => {
            return {
              startTime: entry.startTime,
              renderTime: entry.renderTime,
              element: `${entry.element.tagName}: ${entry.element.className}`
            };
          })
        };
        vitalParams.push(Object.assign(report, newReport));
      } else {
        vitalParams.push(report);
      }
      webVitalsReportEl.innerHTML = `[${vitalParams
        .map(vitalParam => JSON.stringify(vitalParam))
        .join(',')}]`;
    }

    webVitals.getCLS(logReport);
    webVitals.getFID(logReport);
    webVitals.getLCP(logReport);
    webVitals.getFCP(logReport);
  };
  document.head.appendChild(script);
}

/*
 * Intitalizes a bouncy currency by default values and exposes init method to change its currencies and time
 */
let Bounce = (function() {
  const bouncer = document.getElementsByClassName('bouncy-currency')[0];
  let currencies = ['€', '£', '$', '₽', '¥'],
    bouncerCurrency,
    interval = 1300,
    timer;

  /**
   * Method to change time of bounce or list of currencies
   * @param {number} time
   * @param {array} currencyList
   */
  const init = (time = 2000, currencyList = currencies) => {
    bouncerCurrency = bouncer.getElementsByClassName('curr')[0];
    currencies = currencyList;
    interval = time;
    start();
  };

  /**
   * Method to start the coin bouncing
   */
  const start = () => {
    let index = 0;
    timer ? clearInterval(timer) : null;
    timer = setInterval(() => {
      bouncerCurrency.innerText = currencies[index];
      index++;
      if (index >= currencies.length) index = 0;
    }, interval);
    bouncer.addEventListener('mouseover', () => {
      bouncer.classList.add('pause');
    });
    bouncer.addEventListener('mouseout', () => {
      bouncer.classList.remove('pause');
    });
  };

  // If page is not home page start bouncer
  if (
    !(document.getElementsByTagName('body')[0].getAttribute('id') == 'home')
  ) {
    bouncer && init();
  }

  return {
    init: init
  };
})();

export default Bounce;

import lj from '../lumberjack';

// For a section to be assumed viewed, the top of it should be above half of the screen
export default function fireSectionViewed(allSections) {
  let triggeredSections = '';
  return () => {
    allSections.forEach(section => {
      if (section.getBoundingClientRect().top < window.innerHeight / 2) {
        let parentSection = section.closest('[data-fold-order]');
        if (parentSection) {
          let sectionName =
            parentSection.getAttribute('data-name') || 'unknown';
          let foldCount =
            parentSection.getAttribute('data-fold-order') || 'unknown';
          if (!triggeredSections.includes(sectionName)) {
            lj.pushEvents({
              event_name: 'website.page_section',
              event_type: 'viewed',
              properties: {
                section: sectionName,
                url: location.href,
                page_fold: foldCount
              }
            });
            triggeredSections += sectionName;
          }
        }
      }
    });
  };
}

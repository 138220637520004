import { debounce, isElementXPercentInViewport } from './util';

const elements = [
  {
    selector: document.querySelector('#home .carousel-new'),
    screenTime: visibiltyTime({
      gaData: {
        eventLabel: 'Testimonial New',
        eventAction: 'Scroll away - Testimonial New',
        eventCategory: 'Website - Testimonial New'
      },
      isDisabledOnceScrolledAway: true
    }),
    hotjar: hotjarLaunch('testimonial-visible')
  }
];

/**
 * Calculate total time(seconds) spent on a section
 * @param {Object} gaData
 * @param {Boolean} isDisabledOnceScrolledAway
 */
function visibiltyTime({ gaData, isDisabledOnceScrolledAway }) {
  let timeSpentOn = 0;
  let interval = null;
  let isLoggingDisabled = false;

  const sendGaEvent = () => {
    if (gaData) {
      gaData.eventLabel = `${gaData.eventLabel} - ${timeSpentOn}s`;
      window.rzpAnalytics(gaData);
    }
  };

  const logVisibleTime = isVisible => {
    if (isLoggingDisabled) {
      return;
    }
    if (!interval) {
      if (isVisible) {
        interval = setInterval(() => {
          timeSpentOn++;
        }, 1000);
      }
    } else {
      if (!isVisible && interval) {
        clearInterval(interval);
        // if user scrolls away from section
        if (timeSpentOn >= 0) {
          sendGaEvent();
          if (isDisabledOnceScrolledAway) {
            isLoggingDisabled = true;
          }
        }
        interval = null;
      }
    }
  };

  return {
    timeSpentOn,
    logVisibleTime
  };
}

/**
 * Trigger hotjar event
 * @param {String} triggerName
 */
function hotjarLaunch(triggerName) {
  let triggered = false;

  const fireHotjar = () => {
    if (typeof hj === 'function') {
      hj('trigger', triggerName);
      return true;
    }
    return false;
  };

  const logHotjar = () => {
    if (!triggered) {
      triggered = fireHotjar();
    }
  };

  return {
    logHotjar
  };
}

const isElementFound = elements.some(element => element.selector);

if (isElementFound) {
  window.addEventListener(
    'scroll',
    debounce(() => {
      checkVisibility(elements);
    }, 200)
  );
}

/**
 * Check visibility of dom sections/elements and fire callbacks according to requirement
 * @param {Object} views
 */
function checkVisibility(views) {
  views.forEach(view => {
    if (view.selector && isElementXPercentInViewport(view.selector, 80)) {
      if (view.hotjar) {
        view.hotjar.logHotjar();
      }
      if (view.screenTime) {
        view.screenTime.logVisibleTime(true);
      }
    } else {
      if (view.screenTime) {
        view.screenTime.logVisibleTime(false);
      }
    }
  });
}

import { getUrlVars } from './modules/util';
import { qsa, qs, gtag, getExperimentID, customeEventsToGTM } from './util';
import { getLjEventProps } from './getLjEventProps';
import { trackSegmentAndLJ } from './segment/segment-utils';

(function() {
  const prodKey = 'ruYL2LOgRsWiI148xwqmDQ';
  const betaKey = 'vTfHw6xHRXm8JelpO0p31Q';
  let isEasyOnboarding = false;

  const MERCHANT_DASHBOARD_URL = 'https://dashboard.razorpay.com/signup';
  const EASY_ONBOARDING_URL = 'https://easy.razorpay.com/onboarding';
  const eventName = 'Sign Up CTA';

  qs('.signupCTA') &&
    qsa('.signupCTA').forEach(node => {
      node.addEventListener('click', e => {
        const ljEventProps = getLjEventProps();
        const isMobile = window.innerWidth < 769;

        trackSegmentAndLJ(
          e,
          eventName,
          Object.assign(ljEventProps, {
            pageUrl: window.location.href,
            device_type: isMobile ? 'mweb' : 'dweb',
            target_page: e.currentTarget.href,
            fold: node.getAttribute('data-location') || '', // backward compatibity, hence forth use location instaed of fold.
            location: node.getAttribute('data-location') || '',
            source: isMobile ? 'Mobile Website' : 'Website',
            slug: window.location.origin + window.location.pathname,
            mode: 'null',
            userRole: 'null',
            userId: 'null',
            merchantId: 'null',
            experiment_ID: 'easy_onboarding',
            easyOnboarding: isEasyOnboarding
          }),
          true
        );

        customeEventsToGTM({
          event: `${eventName} Clicked`,
          pageUrl: window.location.href,
          device_type: isMobile ? 'mweb' : 'dweb',
          target_page: e.currentTarget.href,
          fold: node.getAttribute('data-location') || '', // backward compatibity, hence forth use location instaed of fold.
          location: node.getAttribute('data-location') || '',
          source: isMobile ? 'Mobile Website' : 'Website',
          slug: window.location.origin + window.location.pathname,
          mode: 'null',
          userRole: 'null',
          userId: 'null',
          merchantId: 'null',
          easyOnboarding: isEasyOnboarding
        });
      });
    });

  gtag('event', 'optimize.callback', {
    name: getExperimentID(prodKey, betaKey),
    callback: value => {
      // Easy Onboarding Flow - Modifing URLs in the DOM
      if (value === '1') {
        isEasyOnboarding = true;
        const query = `a[href*="${MERCHANT_DASHBOARD_URL}"]`;

        qs(query) &&
          qsa(query).forEach(node => {
            const vars = getUrlVars(node.href);
            vars.source = 'website';
            const queryParams = new URLSearchParams(vars).toString();
            node.href = `${EASY_ONBOARDING_URL}?${queryParams}`;
          });
      }
    }
  });
})();

/* Analytics Code */

var _rzpAQ = [], //GA Queue
  _rzpAQ_fbq = [], //FB Queue
  _rzpAQ_liq = []; //LinkedIn Queue

var _qChckr = setInterval(emptyRzpQueue(_rzpAQ), 500),
  _fbqChckr = setInterval(emptyRzpQueue(_rzpAQ_fbq), 500),
  _liqChckr = setInterval(emptyRzpQueue(_rzpAQ_liq), 500);

var events = [
  { name: 'ga', q: _rzpAQ, checker: _qChckr },
  { name: 'fb', q: _rzpAQ_fbq, checker: _fbqChckr },
  { name: 'linkedin', q: _rzpAQ_liq, checker: _liqChckr }
];

function clearQueue(queue) {
  for (var i = 0; i < queue.length; i++) {
    if (window.rzpAnalytics && typeof window.rzpAnalytics === 'function') {
      window.rzpAnalytics(queue[i]);
    }
  }
}

function checkAnalytics(item, data) {
  let isAnalyticsPresent = false;
  events.map(event => {
    if (item === event.name) {
      // If analytics is undefined, push to respective queue
      if (typeof window.razorpayAnalytics === 'undefined') {
        event.q.push(data);
        isAnalyticsPresent = false;
      } else {
        clearInterval(event.checker);
        emptyRzpQueue(event.q);
        isAnalyticsPresent = true;
      }
    }
  });
  return isAnalyticsPresent;
}

function emptyRzpQueue(queue) {
  if (typeof window.razorpayAnalytics === 'undefined' || ![queue].length) {
    return;
  }
  let q = [].concat(queue);
  arguments[0] = [];
  clearQueue(q);
}

/**
 * Method to track Analytics
 * @param {Object} eventData Data of the event
 * @param {Function} cb Callback
 */
window.rzpAnalytics = function(data, cb = function() {}) {
  // If there's no data, don't track anything
  if (!data) {
    cb(data);
    return;
  }

  if (data.fbevent) {
    if (!checkAnalytics('fb', data.fbevent)) return;
    razorpayAnalytics.track('fb', data.fbevent);
    delete data.fbevent;
  }
  if (data.linkedinconversionid) {
    //lytics converts the event names to all lowercase
    if (!checkAnalytics('linkedin', data.linkedinconversionid)) return;
    emitLinkedIn(data.linkedinconversionid);
    delete data.linkedinconversionid;
  }

  if (data.name === 'set_dimensions') {
    if (!checkAnalytics(data)) return;
    ga('set', data.dimensions);
    ga('old.set', data.dimensions);
  } else {
    if (!checkAnalytics('ga', data)) {
      return;
    }
    ga(
      'send',
      'event',
      data.eventCategory || window._eventCategory,
      data.eventAction || undefined,
      data.eventLabel || undefined,
      data.eventValue || undefined,
      {
        hitCallback: function() {
          cb(data);
        }
      }
    );
    ga(
      'old.send',
      'event',
      data.eventCategory || window._eventCategory,
      data.eventAction || undefined,
      data.eventLabel || undefined,
      data.eventValue || undefined,
      {
        hitCallback: function() {
          cb(data);
        }
      }
    );
  }
};

export function emitLinkedIn(data, txn_id = {}) {
  let event = {};
  event.conversionId = data;
  if (Object.keys(txn_id).length > 0) {
    event.txn_id = txn_id;
  }
  razorpayAnalytics.track('linkedin', '', event);
}

import lj from '../lumberjack';
import { qsa } from './util';

function homePageEvents() {
  let allLjItems = qsa('.lj-event');
  allLjItems.forEach(ljItem => {
    ljItem.addEventListener('click', () => {
      let parentSection = ljItem.closest('[data-fold-order]');
      if (parentSection) {
        let title = ljItem.getAttribute('data-name') || 'unknown';
        let page_fold =
          parentSection.getAttribute('data-fold-order') || 'unknown';
        let eventName = parentSection.getAttribute('data-lj') || 'unknown';
        eventName = `website.${eventName}`;
        let redirect_url = `${location.hostname}${ljItem.getAttribute('href')}`;
        lj.pushEvents({
          event_name: eventName,
          event_type: 'initiated',
          properties: {
            title,
            url: location.href,
            page_fold,
            redirect_url
          }
        });
      }
    });
  });

  // CTA events(signup, know more)
  // Event property values are picked from html.
  // Parent(any level) can have below attributes which will be sent as different props in event:
  // data-fold-order(page_fold), data-name(source), data-service(service), href(redirect_url).
  // if not an anchor then "redirect_url" will not be passed as property.
  const ctaList = [
    {
      selector: '.signupCTA',
      eventName: 'signup_cta_click'
    },
    {
      selector: '.signup-banner',
      eventName: 'signup_cta_click'
    }
  ];
  let isExperimentIframeOn = 'no';
  const setCtaEvent = (cta, eventName) => {
    const ctaClosestSection = cta.closest('[data-fold-order]');
    if (ctaClosestSection) {
      const ctaSource =
        ctaClosestSection.getAttribute('data-name') || 'unknown';
      const page_fold =
        ctaClosestSection.getAttribute('data-fold-order') || 'unknown';
      const service = ctaClosestSection.getAttribute('data-service') || 'PG';
      let href = cta.getAttribute('href') || '';
      var pathRegex = /^https?:\/\//i;
      if (href && !pathRegex.test(href)) {
        href = `${location.origin}${href}`;
      }
      cta.addEventListener('click', () => {
        lj.pushEvents({
          event_name: `website.${eventName}`,
          event_type: 'initiated',
          properties: {
            source: ctaSource,
            service,
            redirect_url: href,
            source_url: `${location.hostname}${location.pathname}`,
            page_fold,
            experiment_iframe: isExperimentIframeOn
          }
        });
      });
    }
  };
  ctaList.forEach(ctaItem => {
    qsa(ctaItem.selector).forEach(cta => {
      setCtaEvent(cta, ctaItem.eventName);
    });
  });

  /**
   * Optimize A/B Experiment between iframe signup and signup buttons(nav and hero section)
   */
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  let signupIframeExpId = 'Y3cknuQTT9KuOpSmmj7afA'; // beta exp by default
  let dasboardUrl =
    'https://beta-dashboard.stage.razorpay.in/signup?auth_source=website_homepage';
  if (location.hostname === 'razorpay.com') {
    dasboardUrl =
      'https://dashboard.razorpay.com/signup?auth_source=website_homepage';
    signupIframeExpId = 'OBv-SPBjRdKlCfs7mm8rOQ'; // prod exp ID
  }
  function setSignupExpABVersion(value) {
    if (value === '1') {
      isExperimentIframeOn = 'yes';
      document
        .querySelector('#banner .right-content iframe')
        .setAttribute('src', dasboardUrl);
      document.querySelector('body').classList.add('signup-in-iframe');
      lj.pushEvents({
        event_name: 'website.iframe_signup_homepage',
        event_type: 'success'
      });
    }
  }

  gtag('event', 'optimize.callback', {
    name: signupIframeExpId,
    callback: setSignupExpABVersion
  });
}

export default homePageEvents;

import { toTitleCase } from '../modules/util';

/**
 * Global function to fire events to segment directly
 * @param eventName
 * @param properties
 */

export function emitSegment(
  eventName = '',
  properties = {},
  toCleverTap = false
) {
  // This is segment's analytics window object not our analytics object
  if (
    window.analytics &&
    window.analytics.track &&
    typeof window.analytics.track === 'function'
  ) {
    window.analytics.track(eventName, properties, {
      integrations: {
        CleverTap: toCleverTap
      }
    });
  }
}

/**
 * Function to transform lj events tos segment format
 * @param eventName
 * @param properties
 */
export function transformLJEventsForSegment(
  eventName,
  properties,
  toCleverTap
) {
  let event = toTitleCase(eventName.replace(/[_.]/g, ' '));
  emitSegment(event, properties, toCleverTap);
}

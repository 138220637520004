/**
 * To calculate the true bounce rate by adding a condition that the user
 * has stayed on a page for 20sec(variable) and has scrolled to the footer(variable).
 */

import { debounce } from './modules/util';

(function() {
  /**
   * Counter for seconds elapsed
   * @type {number}
   */
  let counter = 0;
  let timerId = setInterval(function count() {
    counter = counter + 1000; // milliseconds
  }, 1000);

  /**
   *
   * @type {Array} Array of Objects {node:'', data:{}} - node is the element till where we want user to scroll
   */
  let elements = [];

  let footer = document.getElementsByTagName('footer')[0];
  let data = {
    eventCategory: 'Website - Footer',
    eventAction: 'Scrolled to footer'
  };

  elements.push({ node: footer, data, timer: 20000 });

  let body = document.getElementsByTagName('body')[0];
  let bodyId = body.getAttribute('id');

  /**
   * Only applicable for home page
   */
  if (bodyId === 'home') {
    let testimonials = document.getElementById('testimonials');
    let data = {
      eventCategory: 'Website - Testimonials',
      eventAction: 'Scrolled to testimonials'
    };

    elements.push({ node: testimonials, data, timer: 20000 });
  }

  /**
   * Only applicable for payment gateway page
   */
  if (bodyId === 'payments') {
    let pricingSection = document.getElementById('pricing-section');
    let data = {
      eventCategory: 'Website - Pricing Card',
      eventAction: 'Scrolled to pricing'
    };

    elements.push({ node: pricingSection, data, timer: 20000 });
  }

  elements.map(el => {
    el.isGAFired = false;
    return el;
  });

  let maxTimerVal = 0, //maxTimerVal is the highest value of timer in the arrays of sections
    maxThreshold = 20000; //maxThreshold is required as to stop the counter

  window.addEventListener('scroll', debounce(sendGA, 50));

  function sendGA() {
    elements.map(el => {
      if (el.node && isElementVisible(el) && counter >= el.timer) {
        if (el.timer > maxTimerVal) {
          maxTimerVal = el.timer;
        }
        if (!el.isGAFired) {
          ga('send', 'event', el.data);
          el.isGAFired = true;
        }
      }
    });
    if (counter > maxTimerVal && counter > maxThreshold) {
      clearInterval(timerId);
    }
  }

  let isElementVisible = element => {
    return element.node.offsetTop - window.pageYOffset <= 200;
  };
})();
